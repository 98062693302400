import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { PlusSquare, DashSquare } from 'react-bootstrap-icons';
import ReactDiffViewer from 'react-diff-viewer';
import stringify from 'json-stable-stringify';
import './comparisson-row.scss'


function ComparissonRow({deployment, color}) {

    const [isExpanded, setIsExpanded] = useState(false)

    return (
        <div>
            <Row className={`${color}-result row-wrapper`}>
                <Col>
                    {isExpanded
                    ? <DashSquare className="me-2" onClick={() => {setIsExpanded(!isExpanded)}}/>
                    : <PlusSquare className="me-2" onClick={() => {setIsExpanded(!isExpanded)}}/> }
                    {deployment.deployment}
                    
                </Col>
                <Col>{deployment['env1'].version}</Col>
                <Col>{deployment['env2'].version}</Col>
            </Row>
            {isExpanded 
            ?<Row>
                <ReactDiffViewer disableWordDiff={true} oldValue={stringify(deployment['env1'], {space: ' '})} newValue={stringify(deployment['env2'], {space: ' '})} />
            </Row>
            : <></>}
            
        </div>
    )
}


export default ComparissonRow;

