import axios from "axios";
import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Spinner } from "react-bootstrap";

function ValidationPage() {

  const [isLoading, setIsLoading] = useState(true);
  const [validations, setValidations] = useState([]);

  useEffect(() => {
    async function fetchValidations() {
      const payload = await axios.post(`${process.env.REACT_APP_REGISTRY_URL}/command/validate`)
      const parsed = payload.data.map((validation, index) => {
        return {
            ...validation,
            current: JSON.stringify(validation.current),
            suggested: JSON.stringify(validation.suggested),
            severity: validation.message.includes('Request') ? 1 : 0 ,
            id: index
        }
      })
      setValidations(parsed)
      setIsLoading(false)
    }
    fetchValidations()
  }, [])

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
      filter: textFilter()
    },
    {
      dataField: "cluster.label",
      text: "Cluster ",
      sort: true,
      filter: textFilter()
    },
    {
      dataField: "deployment.namespace",
      text: "Namespace",
      sort: true,
      filter: textFilter()
    },
    {
      dataField: "deployment.name",
      text: "Name",
      sort: true,
      filter: textFilter()
    },
    {
      dataField: "message",
      text: "Message",
      sort: true,
      filter: textFilter()
    },
    {
      dataField: "current",
      text: "Current ",
      sort: true,
      filter: textFilter(),
      formatter: (cell, row) => {
        const obj = JSON.parse(cell);
        const suggested = JSON.parse(row.suggested);
        return <><span><b>CPU: </b><span style={{color: `${suggested.cpu !== obj.cpu ? 'red' : 'inherit'}`}}>{obj.cpu}</span></span><br/><span><b>Memory: </b><span style={{color: `${suggested.mem !== obj.memory ? 'red' : 'inherit'}`}}>{obj.memory}</span></span></>;
      }
    },
    {
      dataField: "suggested",
      text: "Suggested ",
      sort: true,
      filter: textFilter(),
      formatter: (cell) => {
        const obj = JSON.parse(cell);
        return <><span><b>CPU: </b>{obj.cpu}</span><br/><span><b>Memory: </b>{obj.mem}</span></>;
      }
    },
  ];

  console.log("validations", validations)

  return (
    <>
      <h2 style={{ marginBottom: '1rem' }}>Validations</h2>
      {isLoading ?
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '200px' }}>
          <p>Loading validations...</p>
          <Spinner animation="border" role="status">
            <span className="visually-hidden" style={{ color: 'black' }}>Loading...</span>
          </Spinner>
        </div> : <BootstrapTable
          keyField="id"
          bootstrap4
          data={validations}
          columns={columns}
          pagination={paginationFactory({ sizePerPage: 15 })}
          filter={filterFactory()}
          //striped
          bordered={false}
          hover
        />}
    </>
  )
}


export default ValidationPage;

