import React from "react";

function DeployIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
    >
      <path
        fill="none"
        stroke="#000"
        strokeWidth="2"
        d="M23 1s-6.528-.458-9 2c-.023.037-4 4-4 4L5 8l-3 2 8 4 4 8 2-3 1-5s3.963-3.977 4-4c2.458-2.472 2-9 2-9zm-6 7a1 1 0 110-2 1 1 0 010 2zM7 17c-1-1-3-1-4 0s-1 5-1 5 4 0 5-1 1-3 0-4z"
      ></path>
    </svg>
  );
}

export default DeployIcon;