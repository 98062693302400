import { Checkbox } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, ButtonGroup, Col, FloatingLabel, Form, Row, Spinner } from "react-bootstrap";
import { BarLoader, RotateLoader } from "react-spinners";
import Cluster from "../cluster/cluster";
import ComparissonModal from "../comparisson-modal/comparisson-modal";
import DeployIcon from "../svg-icons/deploy-icon";
import './deploy-page.scss'
import WorkerMonitoring from "../worker-monitoring/worker-monitoring";

function DeployPage() {

    const [clusters, setClusters] = useState([])
    const [selectedCluster, setSelectedCluster] = useState(null)
    const [selectedNamespace, setSelectedNamespace] = useState('')
    const [deploymentsInNamespace, setDeploymentsInNamespace] = useState([])
    const [selectedDeployment, setSelectedDeployment] = useState('')
    const [newImageTag, setNewImageTag] = useState('')
    const [newImageFull, setNewImageFull] = useState('')
    const [waitingForCommand, setWaitingForCommand] = useState(false)
    const [commandResult, setCommandResult] = useState('')

    const [doMonitoring, setDoMonitoring] = useState(false)
    const [monitoredWorker, setMonitoredWorker] = useState({})

    useEffect(() => {
        async function fetchClusters() {
            const payload = await axios.get(`${process.env.REACT_APP_REGISTRY_URL}/fetch/clusters_detailed`)
            payload.data.forEach((cluster) => {
                cluster.namespaces = [...new Set(cluster.deployments.map((deployment) => deployment.namespace))]
            })
            setClusters(payload.data)
            setSelectedCluster(payload.data[0])
        }
        fetchClusters()
    }, [])


    useEffect(() => {
        if (selectedCluster != null) {
            setSelectedNamespace(selectedCluster.namespaces[0])
            setDeploymentsInNamespace(selectedCluster.deployments.filter((deployment) => deployment.namespace == selectedCluster.namespaces[0]))
        }
    }, [selectedCluster])

    useEffect(() => {
        if (selectedCluster != null) {
            const deployments = selectedCluster.deployments.filter((deployment) => deployment.namespace == selectedNamespace)
            setDeploymentsInNamespace(deployments)
            setSelectedDeployment(deployments[0])
        }
    }, [selectedNamespace])

    useEffect(() => {
        if (selectedDeployment != null && selectedDeployment.image != null) {
                const baseImage =selectedDeployment.image.split(':')[0]
                setNewImageFull(`${baseImage}:${newImageTag}`)
        }
    }, [newImageTag])

    //apps_api, namespace, deployment, new_image
    const onDeploy = async () => {
        const payload = {
            cluster_info: {
                label: selectedCluster.label,
                client_id: selectedCluster.client_id,
                environment: selectedCluster.environment,
                implementation_shortname: selectedCluster.implementation_shortname
            }, command: {
                name: 'image-tag-change',
                namespace: selectedNamespace,
                deployment: selectedDeployment.name,
                new_image: newImageFull
            }}
        let reply = axios.post(`${process.env.REACT_APP_REGISTRY_URL}/command/issue_deploy_command`, payload)
        setCommandResult('')
        setWaitingForCommand(true)
        reply = await reply
        console.log(reply.data.error)
        if (!reply.data.error) {
            setDoMonitoring(true)
            setMonitoredWorker({
                cluster: selectedCluster,
                namespace: selectedNamespace,
                deployment: selectedDeployment
            })
        }

        setWaitingForCommand(false)
        setCommandResult(reply.data.result)
        console.log(reply)
    }

    return (
        <div className="mb-5 deploy-page">
            <h2 style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center' }}>{selectedCluster === null && <Spinner animation="grow" variant="primary" style={{marginRight: '7px'}} />} Deploy</h2>
            {selectedCluster !== null 
            && <div>
                <Row className="mt-5 justify-content-center">
                    <Col className="align-self-end" sm={6}>
                        <h4>Cluster</h4>
                        <FloatingLabel label="Cluster">
                            <Form.Select value={selectedCluster.id} onChange={(e) => {setSelectedCluster(clusters.find((cluster) => cluster.id === Number(e.target.value)))} }>
                                {clusters.sort((a, b) => a.label.localeCompare(b.label)).map((cluster, key) => {
                                    return(<option key={key} value={cluster.id}>{cluster.label}</option>)
                                })}
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="mt-3 justify-content-center">
                    <Col className="align-self-end" sm={6}>
                    <h4>Namespace</h4>
                        <FloatingLabel label="Namespace">
                            <Form.Select value={selectedNamespace} onChange={(e) => {setSelectedNamespace(e.target.value)} }>
                                {selectedCluster.namespaces.map((namespace, key) => {
                                    return(<option key={key} value={namespace}>{namespace}</option>)
                                })}
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="mt-3 justify-content-center">
                    <Col className="align-self-end" sm={6}>
                        <h4>Deployment</h4>
                        <FloatingLabel label="Deployment">
                            <Form.Select value={selectedDeployment.name} onChange={(e) => {setSelectedDeployment(deploymentsInNamespace.find((deployment) => deployment.name == e.target.value))} }>
                                {deploymentsInNamespace.map((deployment, key) => {
                                    return(<option key={key} value={deployment.name}>{deployment.name}</option>)
                                })}
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="mt-3 justify-content-center">
                    <Col className="align-self-end" sm={6}>
                        <h4>Current image</h4>
                        <p className="align-baseline">{selectedDeployment.image}</p>
                    </Col>
                </Row>
                <Row className="mt-0 justify-content-center">
                    <Col className="align-self-end" sm={6}>
                        <h4>New image tag</h4>
                        <Form.Control placeholder="New image tag" value={newImageTag} onChange={(event) => setNewImageTag(event.target.value)}/>
                    </Col>
                </Row>
                <Row className="mt-3 justify-content-center">
                    <Col className="align-self-end" sm={6}>
                        <h4>Will deploy</h4>
                        <p>{newImageFull ? newImageFull : <span style={{opacity: 0.5}}>No data</span>}</p>
                    </Col>
                </Row>
                <Row className="mt-5 justify-content-center">
                    <Col className="justify-content-center d-flex" sm={6}>
                        <Button className="deploy-btn" style={{minWidth: '150px'}} onClick={onDeploy}><DeployIcon />DEPLOY </Button>
                    </Col>
                </Row>
                <Row className="mt-3 justify-content-center">
                    <Col className="align-self-end" sm={6}>
                        {waitingForCommand 
                        ? <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center'}}>
                            <BarLoader></BarLoader>
                            Waiting for command to execute
                        </div>
                        : <div>
                            {commandResult}
                        </div>}
                    </Col>
                </Row>
                <Row className="mt-3 justify-content-center">
                    <Col className="align-self-end" sm={6}>
                        {doMonitoring 
                        ? <div>
                            <WorkerMonitoring monitoredWorker={monitoredWorker}/>
                        </div>
                        : <div>
                        </div>}
                    </Col>
                </Row>

            </div>}
        </div>
    )
}


export default DeployPage;
