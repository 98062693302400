import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import stringify from "json-stable-stringify";
import ComparissonRow from "../comparisson-row/comparisson-row";

function ComparissonResult({
  comparissonList,
  environment1,
  environment2,
  clusters,
}) {
  const [diffVersionList, setDiffVersionList] = useState([]);
  const [matchingVersionList, setMatchingVersionList] = useState([]);
  const [completelyMatchingList, setCompletelyMatchingList] = useState([]);

  // const download = () => {
  //     const headerLine = `Deployment, ${clusters[0].label} ${environment1}, ${clusters[1].label} ${environment2}`
  //     const lines = displayList.map((comparisson) => `${comparisson.deployment}, ${comparisson['env1']}, ${comparisson['env2']}`)
  //     const resArray = [headerLine, ...lines]
  //     const resString = resArray.join('\n')
  //     const blob = new Blob([resString])
  //     const url = window.URL.createObjectURL(new Blob([blob]))
  //     const link = document.createElement('a')
  //     link.href = url
  //     link.setAttribute('download', 'export.csv')
  //     document.body.appendChild(link)
  //     link.click()
  //     link.parentNode.removeChild(link)
  // }

  useEffect(() => {
    setDiffVersionList(
      comparissonList.filter(
        (comparisson) =>
          comparisson["env1"].version !== comparisson["env2"].version
      )
    );
    const matchingVersionList = comparissonList.filter(
      (comparisson) =>
        comparisson["env1"].version === comparisson["env2"].version
    );
    setCompletelyMatchingList(
      matchingVersionList?.filter(
        (comparisson) =>
          stringify(comparisson["env1"]) === stringify(comparisson["env2"])
      )
    );
    setMatchingVersionList(
      matchingVersionList?.filter(
        (comparisson) =>
          stringify(comparisson["env1"]) !== stringify(comparisson["env2"])
      )
    );
  }, [comparissonList]);

  return (
    <div style={{overflow: 'hidden'}}>
      <Row>
        <Col>
          <b>Deployment</b>
        </Col>
        <Col>
          <b>
            {clusters[0]?.label} {environment1}
          </b>
        </Col>
        <Col>
          <b>
            {clusters[1]?.label} {environment2}
          </b>
        </Col>
      </Row>
      {diffVersionList?.map((deployment, key) => {
        return (
          <ComparissonRow key={key} deployment={deployment} color={"red"} />
        );
      })}
      {matchingVersionList?.map((deployment, key) => {
        return (
          <ComparissonRow key={key} deployment={deployment} color={"yellow"} />
        );
      })}
      {completelyMatchingList?.map((deployment, key) => {
        return (
          <ComparissonRow key={key} deployment={deployment} color={"green"} />
        );
      })}
    </div>
  );
}

export default ComparissonResult;
