import { Checkbox } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, ButtonGroup, Col, FloatingLabel, Form, Row, Spinner } from "react-bootstrap";
import { BarLoader, RotateLoader } from "react-spinners";
import Cluster from "../cluster/cluster";
import ComparissonModal from "../comparisson-modal/comparisson-modal";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import DeployIcon from "../svg-icons/deploy-icon";

function QueueReportPage() {

    const [clusters, setClusters] = useState([])
    const [selectedCluster, setSelectedCluster] = useState(null)
    const [waitingForCommand, setWaitingForCommand] = useState(false)
    const [status, setStatus] = useState("")
    const [fetchedData, setFetchedData] = useState(null)
    const [namespace, setNamespace] = useState('')
    const [queuesToDisplay, setQueuesToDisplay] = useState([])

    useEffect(() => {
        async function fetchClusters() {
            const payload = await axios.get(`${process.env.REACT_APP_REGISTRY_URL}/fetch/clusters`)
            setClusters(payload.data)
            setSelectedCluster(payload.data[0])
        }
        fetchClusters()
    }, [])

    useEffect(() => {
        if (fetchedData != null && fetchedData.length != 0) {
            setNamespace(fetchedData[0].namespace)
        }
    }, [fetchedData])

    useEffect(() => {
        if (fetchedData != null) {
            const found = fetchedData.find((mqInstance) => mqInstance.namespace == namespace)
            setQueuesToDisplay(found.queue_data)
        }
    }, [namespace])


    const fetchMQInfo = async () => {
        const payload = {
            cluster_info: selectedCluster,
            command: {
                name: 'scrape-rabbitmq'
            }
        }
        try {
            setWaitingForCommand(true)
            const reply = await axios.post(`${process.env.REACT_APP_REGISTRY_URL}/command/issue_mq_scrape_command`, payload)
            if (reply.data.error) {
                throw 'Failed to fetch mq data'
            }
            setFetchedData(reply.data.result)
            console.log(reply.data.result)

            setStatus('')
        } catch (e) {
            setStatus(`${e}`)
        } finally {
            setWaitingForCommand(false)
        }
  }

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      filter: textFilter()
    },
    {
      dataField: "messages_ready",
      text: "Ready",
      sort: true,
      filter: textFilter()
    },
    {
      dataField: "messages_unacknowledged",
      text: "Unacknowledged",
      sort: true,
      filter: textFilter()
    },
    {
      dataField: "messages",
      text: "Total",
      sort: true,
      filter: textFilter()
    },
  ];

    return (
        <div className="mb-5 deploy-page">
            <h2 style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center' }}>{selectedCluster === null && <Spinner animation="grow" variant="primary" style={{marginRight: '7px'}} />} Rabbit MQ status</h2>
            {selectedCluster !== null 
            && <div>
                <Row className="mt-5 justify-content-center">
                    <Col className="align-self-end" sm={6}>
                        <h4>Cluster</h4>
                        <FloatingLabel label="Cluster">
                            <Form.Select value={selectedCluster.id} onChange={(e) => {setSelectedCluster(clusters.find((cluster) => cluster.id == e.target.value))} }>
                                {clusters.map((cluster, key) => {
                                    return(<option key={key} value={cluster.id}>{cluster.label}</option>)
                                })}
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="mt-5 justify-content-center">
                    <Col className="justify-content-center  d-flex" sm={6}>
                        <Button onClick={fetchMQInfo} style={{minWidth: '150px'}}>Fetch Queue Status</Button>
                    </Col>
                </Row>
                <Row className="mt-3 justify-content-center">
                    <Col className="align-self-end" sm={6}>
                        {waitingForCommand 
                        ? <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center'}}>
                            <BarLoader></BarLoader>
                            Fetching data...
                        </div>
                        : <div>
                            {status}
                        </div>}
                    </Col>
                </Row>
                {fetchedData != null 
                ? <div>
                    <Row className="mt-5 mb-5 justify-content-center">
                        <Col className="align-self-end" sm={6}>
                            <h4>Namespace</h4>
                            <FloatingLabel label="Cluster">
                                <Form.Select value={namespace} onChange={(e) => {setNamespace(e.target.value)} }>
                                    {fetchedData.map((mqInstance, key) => {
                                        return(<option key={key} value={mqInstance.namespace}>{mqInstance.namespace}</option>)
                                    })}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <BootstrapTable
                        keyField="id"
                        bootstrap4
                        data={queuesToDisplay}
                        columns={columns}
                        filter={filterFactory()}
                        //striped
                        bordered={false}
                        hover
                    />
                </div>
                : <div>
                </div>}

            </div>}
        </div>
    )
}


export default QueueReportPage;
