import './App.css';
import './app.scss';
import './responsive.scss';
import 'bootstrap/dist/css/bootstrap.min.css';


import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Images from './images/images';
import Clusters from './clusters/clusters';
import SingleImagePage from './single-image-page/single-image-page';
import { configureAxios } from './util/configure-axios';
import Queries from './queries/queries';
import { StrictMode, useEffect, useState } from 'react';
import NavBar from './navbar/navbar';
import ClusterComparissonPage from './cluster-comparisson-page/cluster-comparisson-page';
import DeployPage from './deploy-page/deploy-page';
import PrivateRoute from './private-route/private-route';
import SingleClusterPage from './single-cluster-page/single-cluster-page';
import Home from './home/home';
import { Container, Spinner } from 'react-bootstrap';
import SingleImage from './image/single-image';
import ValidationPage from './validation-page/validation-page';
import HistoryViewPage from './history-view-page/history-view-page';
import LogQueryPage from './log-query-page/log-query-page'
import QueueReportPage from './queue--report-page/queue-report-page';
import AdminPage from './admin-page/admin-page';
import Footer from './footer/footer';
import LogDownloadPage from './log-download-page/log-download-page';
import SingleDeploymentHistory from './single-deployment-history/single-deployment-history';
import { useAuth, hasAuthParams } from "react-oidc-context";


function App() {
  const [userLoaded, setUserLoaded] = useState(false)
  const [hasTriedSignin, setHasTriedSignin] = useState(false)

  const auth = useAuth();

  //automatic login
  //this use effect triggers on any change of auth or hasTriedSignIn state
  //on any change it evaluates again if it should attempt login or if the user became authenticated add his token
  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignin) {
          auth.signinRedirect();
          setHasTriedSignin(true);
    } else if (auth.isAuthenticated) {
      setUserLoaded(true)
      configureAxios(auth.user.access_token)
    }

  }, [auth, hasTriedSignin])



  return (
    <div>
        <StrictMode>
          <Router>
            {userLoaded
              ? <><div className='page-wrapper'>
                <NavBar />
                <Container style={{ marginTop: '3rem' }}>
                  <Switch>
                    <Route path="/clusters">
                      <Clusters />
                    </Route>
                    <Route path="/cluster/:id">
                      <SingleClusterPage />
                    </Route>
                    <Route path="/repositories">
                      Repositories
                    </Route>
                    <Route path="/images">
                      <Images />
                    </Route>
                    <Route path="/image/:id">
                      <SingleImage />
                    </Route>
                    <Route path="/image/:id/:tag">
                      <SingleImagePage />
                    </Route>
                    <Route path="/queries">
                      <Queries />
                    </Route>
                    <Route path="/validation">
                      <ValidationPage />
                    </Route>
                    <Route path="/environment-comparisson/:cluster1/:namespace1/:cluster2/:namespace2">
                      <ClusterComparissonPage />
                    </Route>
                    <Route path="/environment-comparisson">
                      <ClusterComparissonPage />
                    </Route>
                    <Route path="/single-deployment-history/:clusterLabel/:namespace/:deploymentName">
                      <SingleDeploymentHistory />
                    </Route>
                    <Route path="/history-view">
                      <HistoryViewPage />
                    </Route>
                    <Route path="/queue-report-page">
                      <QueueReportPage />
                    </Route>
                    <Route path="/deploy-page">
                      <PrivateRoute roles={['DEVOPS']}>
                        <DeployPage />
                      </PrivateRoute>
                    </Route>
                    <Route path="/admin-page">
                      <PrivateRoute roles={['DEVOPS']}>
                        <AdminPage />
                      </PrivateRoute>
                    </Route>
                    <Route path="/log-query-page">
                      <LogQueryPage />
                    </Route>
                    <Route path="/logs-download/:name">
                      <LogDownloadPage />
                    </Route>
                    <Route path="/">
                      <Home />
                    </Route>
                  </Switch>
                  <Footer />
                </Container>
              </div>
              </>
              : <div style={{ width: '100%', minHeight: '300px', display: 'flex', alignItems: 'center', verticalAlign: 'middle' }}>
                <h1 style={{ width: '100%', textAlign: 'center' }}>
                  {/* <Spinner animation="grow" variant="success" />
                  <Spinner animation="grow" variant="info" /> */}
                  <Spinner animation="grow" variant="primary" style={{ marginRight: '1rem' }} />
                  {/* <Spinner animation="grow" variant="warning" /> */}
                  Loading, please wait a moment</h1>
              </div>}
          </Router>
        </StrictMode>
    </div>
  );
}

export default App;
