
import axios from "axios";
import { useEffect, useState } from "react";
import {  Col, Container,  Row, Spinner, Stack, ToggleButtonGroup } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import ReactDiffViewer from 'react-diff-viewer';
import ToggleButton from 'react-bootstrap/ToggleButton';

function SingleDeploymentHistory() {

    const [isLoading, setIsLoading] = useState(true);
    const [histories, setHistories] = useState([])
    const [leftRevision, setLeftRevision] = useState(null)
    const [leftHistory, setLeftHistory] = useState(null)
    const [rightRevision, setRightRevision] = useState(null)
    const [rightHistory, setRightHistory] = useState(null)
    const {clusterLabel, namespace, deploymentName} = useParams()

    useEffect(() => {
        setLeftHistory(histories.find((history) => history.revision == leftRevision))
    }, [leftRevision])

    useEffect(() => {
        setRightHistory(histories.find((history) => history.revision == rightRevision))
    }, [rightRevision])

    useEffect(() => {
        async function fetchDeploymentHistory() {
            const commandPayload = {
                cluster_info: {
                    label: clusterLabel
                },
                command: {
                    name: 'get-deployment-history',
                    deployment_namespace: namespace,
                    deployment_name: deploymentName
                }
            }

            console.log(commandPayload)
            const reply = await axios.post(`${process.env.REACT_APP_REGISTRY_URL}/command/get_deployment_history`, commandPayload)
            setHistories(reply.data.result)
            if (reply.data.result.length >= 2) {
                const length = reply.data.result.length

                setLeftRevision(reply.data.result[length - 2].revision)
                setRightRevision(reply.data.result[length - 1].revision)
                console.log('Did setting!')
            }
            setIsLoading(false)

        }
        fetchDeploymentHistory()
    }, [])

    return (
        <>
            {isLoading
                ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '200px' }}>
                    <p>Loading history data</p>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden" style={{ color: 'black' }}>Loading...</span>
                    </Spinner>
                </div>
                : <></>
            }
            {histories.length !== 0 
                ? <Container>
                    <h1 className="mb-4">Deployment history for {deploymentName}</h1>
                    <hr></hr>
                    <Row>
                        <Col>
                            <b>Version</b>
                        </Col>
                        {histories.map((historyObject) => {
                            return(
                            <Col key={historyObject.revision}>
                                {historyObject.image.split(':')[1]}
                            </Col>)
                        })}

                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <b>Revision</b>
                        </Col>
                        {histories.map((historyObject) => {
                            return(
                            <Col key={historyObject.revision}>
                                {historyObject.revision}
                            </Col>)
                        })}
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <b>Date</b>
                        </Col>
                        {histories.map((historyObject) => {
                            return(
                            <Col key={historyObject.revision}>
                                {(new Date(historyObject.creation_timestamp * 1000)).toISOString().slice(0,10)}
                            </Col>)
                        })}
                    </Row>
                    <hr/>
                    {histories.length >= 2 && leftHistory !== undefined && rightHistory !== undefined
                    ? <span>
                        <Row className="mt-4">
                            <Col md={3}>
                                <span className="me-4"><b>Compare revision left</b></span>
                            </Col>
                            <Col>
                                <Stack direction="horizontal">
                                        <ToggleButtonGroup name="left" className="mb-2 me-2">
                                                {console.log(histories)}
                                                {console.log(leftRevision)}
                                            {histories.map((history, idx) => {console.log(history.revision == leftRevision)
                                            return(
                                            <ToggleButton
                                                key={idx}
                                                active={history.revision == leftRevision}
                                                id={`radio-${idx}`}
                                                type="radio"
                                                variant="outline-primary"
                                                name="radio"
                                                checked={true}
                                                value={history.revision}
                                                onChange={(e) => setLeftRevision(e.currentTarget.value)}
                                            >
                                                {history.revision}
                                            </ToggleButton>
                                            )})}
                                        </ToggleButtonGroup>
                                </Stack>
                            </Col>
                        </Row>
                        <Row className="mt-4 mb-4">
                            <Col md={3}>
                                <span className="me-4"><b>Compare revision right</b></span>
                            </Col>
                            <Col>
                            <Stack direction="horizontal">
                                <ToggleButtonGroup name="right" className="mb-2 me-2">
                                    {histories.map((history, idx) => (
                                    <ToggleButton
                                        key={idx}
                                        id={`radio-${-idx}`}
                                        type="radio"
                                        variant="outline-primary"
                                        name="radio"
                                        value={history.revision}
                                        active={history.revision == rightRevision}
                                        onChange={(e) => setRightRevision(e.currentTarget.value)}
                                    >
                                        {history.revision}
                                    </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                                
                            </Stack>
                            </Col>
                        </Row>
                    {histories.length >= 2 && leftHistory !== undefined && rightHistory !== undefined 
                        ? <ReactDiffViewer disableWordDiff={true} oldValue={leftHistory.content} newValue={rightHistory.content} />
                        : <></>
                    }
                </span>
                : <></> 
            }

        </Container>
        : <></>
        }
            
        </>
    )
}


export default SingleDeploymentHistory;
