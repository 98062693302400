import axios from "axios";
import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router";
import DoubleRightIcon from "../svg-icons/double-right-icon";

function HistoryViewPage() {

  const [isLoading, setIsLoading] = useState(true);

  const [history, setHistory] = useState([])
  useEffect(() => {
    async function fetchValidations() {
      const historyPayload = await axios.get(`${process.env.REACT_APP_REGISTRY_URL}/fetch/history`)
      const clusterPayload = await axios.get(`${process.env.REACT_APP_REGISTRY_URL}/fetch/clusters`)
      const clusterLabelMap = {}
      clusterPayload.data.forEach((cluster) => {
        clusterLabelMap[cluster.id] = cluster.label
      })
      historyPayload.data.forEach((historyElement) => {
        historyElement.clusterId = clusterLabelMap[historyElement.clusterId]
        historyElement.date = new Date(historyElement.unixTimestamp * 1000).toLocaleString()
      })
      historyPayload.data.sort((a, b) => b.unixTimestamp - a.unixTimestamp)
      setHistory(historyPayload.data)
      setIsLoading(false)
    }
    fetchValidations()
  }, [])


  const columns = [
    {
      dataField: "clusterId",
      text: "Cluster",
      sort: false,
      filter: textFilter()
    },
    {
      dataField: "date",
      text: "Date ",
      sort: false,
      filter: textFilter()
    },
    {
      dataField: "namespace",
      text: "Namespace ",
      sort: false,
      filter: textFilter()
    },
    {
      dataField: "name",
      text: "Name",
      sort: false,
      filter: textFilter()
    },
    {
      dataField: "comment",
      text: "Comment",
      sort: false,
      filter: textFilter()
    },
    {
      dataField: "image",
      text: "Image",
      sort: false,
      filter: textFilter()
    },
    {
      dataField: "replicas",
      text: "Replicas",
      sort: false,
      filter: textFilter()
    },
    // {
    //   dataField: "",
    //   text: "",
    //   formatter: (cell, row, i) => <OverlayTrigger
    //     key={i}
    //     style={{ verticalAlign: 'middle' }}
    //     placement="top"
    //     delay={{ show: 250, hide: 400 }}
    //     overlay={renderTooltip({ text: `Go to Image` })}
    //   ><Button style={{ display: 'flex', whiteSpace: 'nowrap', background: '#dee2e691' }} onClick={() => handleRowClick(row.id)} variant="link"><DoubleRightIcon /></Button></OverlayTrigger>
    // }
  ];

  return (
    <>
      <h2 style={{ marginBottom: '1rem' }}>History</h2>
      {isLoading ?
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '200px' }}>
          <p>Loading history...</p>
          <Spinner animation="border" role="status">
            <span className="visually-hidden" style={{ color: 'black' }}>Loading...</span>
          </Spinner>
        </div> : <BootstrapTable
          keyField="id"
          bootstrap4
          data={history}
          columns={columns}
          pagination={paginationFactory({ sizePerPage: 15 })}
          filter={filterFactory()}
          //striped
          bordered={false}
          hover
        />}
    </>
  )
}


export default HistoryViewPage;

