import { Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { dump } from 'js-yaml'


function QueryResult({ result }) {

  const [styledOutput, setStyledOutput] = useState('')
  const [useYaml, setUseYaml] = useState(true)
  const [showEmpty, setShowEmpty] = useState(true)
  const [showId, setShowId] = useState(false)
  const [showClientId, setShowClientId] = useState(false)
  const [showImplementationShortname, setShowImplementationShortname] = useState(false)
  const [showEnvironment, setShowEnvironment] = useState(false)
  const [showLabel, setShowLabel] = useState(true)
  const [showReplicas, setShowReplicas] = useState(false)

  const onCheckBoxChange = (event, setter) => {
    setter(event.target.checked)
  }

  useEffect(() => {
    if (result != null) {

      let intermidiary = JSON.parse((JSON.stringify(result, null, 2)))

      if (!showEmpty) {
        intermidiary = intermidiary.filter((cluster) => cluster.deployments.length != 0)
      }

      intermidiary.forEach(cluster => {
        if (!showId) {
          delete cluster.id
        }
        if (!showClientId) {
          delete cluster.client_id
        }
        if (!showImplementationShortname) {
          delete cluster.implementation_shortname
        }
        if (!showEnvironment) {
          delete cluster.environment
        }
        if (!showLabel) {
          delete cluster.label
        }

        if (!showReplicas) {
          cluster.deployments.forEach((deployment) => {
            delete deployment.replicas
          })
        }
      })


      intermidiary = intermidiary.map((cluster) => {
        const retVal = { cluster_info: { ...cluster }, deployments: cluster.deployments }
        delete retVal.cluster_info.deployments
        return retVal
      })

      if (useYaml) {
        intermidiary = dump(intermidiary, { indent: 2, sortKeys: true })
      } else {
        intermidiary = JSON.stringify(intermidiary, null, 2)
      }
      setStyledOutput(intermidiary)
    }
  }, [result, useYaml, showEmpty, showId, showClientId, showImplementationShortname, showEnvironment, showLabel, showReplicas])

  return (
    <>
      <h2 style={{ marginTop: '2rem' }}>Output</h2>
      <Row style={{ marginBottom: '3rem' }} className="output-options">
        <Col xs={8}>
          <textarea value={styledOutput} style={{ 'height': '400px', 'width': '100%' }} readOnly>
          </textarea>
        </Col>
        <Col>
          <h4>Output options</h4>
          <Checkbox onChange={(event) => { onCheckBoxChange(event, setUseYaml) }} checked={useYaml} /> Use Yaml instead of JSON <br />
          <Checkbox onChange={(event) => { onCheckBoxChange(event, setShowEmpty) }} checked={showEmpty} /> Show clusters with no query matches <br />
          {/* <Checkbox onChange={(event) => { onCheckBoxChange(event, setShowId) }} checked={showId}/> Show Id <br/> */}
          <Checkbox onChange={(event) => { onCheckBoxChange(event, setShowClientId) }} checked={showClientId} /> ShowClient Id <br />
          <Checkbox onChange={(event) => { onCheckBoxChange(event, setShowImplementationShortname) }} checked={showImplementationShortname} /> Show Implementation Shortname <br />
          <Checkbox onChange={(event) => { onCheckBoxChange(event, setShowEnvironment) }} checked={showEnvironment} /> Show Environment <br />
          <Checkbox onChange={(event) => { onCheckBoxChange(event, setShowLabel) }} checked={showLabel} /> Show Label <br />
          <Checkbox onChange={(event) => { onCheckBoxChange(event, setShowReplicas) }} checked={showReplicas} /> Show Replicas <br />
        </Col>
      </Row>
    </>
  )
}


export default QueryResult;