import { useAuth, hasAuthParams } from "react-oidc-context";
import { jwtDecode } from "jwt-decode";

const PrivateRoute = ({ roles, children }) => {


    const auth = useAuth();
    const isLoggedIn = auth.isAuthenticated
    
    if (!isLoggedIn) return(null)

    const decoded = jwtDecode(auth.user.access_token)
    const acquiredRoles = decoded.realm_access.roles

    let hasAuthorization = true

    if (roles && roles.length != 0) {
        hasAuthorization = false
        roles.forEach((role) => {
            if (acquiredRoles.includes(role)) {
                hasAuthorization = true //only one role is enough to allow user
            }
        })
    }

    return hasAuthorization ? children : null;
};

export default PrivateRoute;