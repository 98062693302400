import axios from "axios";
import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router";
import DoubleRightIcon from "../svg-icons/double-right-icon";

function Images() {

  const renderTooltip = ({ text }) => (<Tooltip>{text}</Tooltip>);
  const [isLoading, setIsLoading] = useState(true);

  const [images, setImages] = useState([])
  useEffect(() => {
    async function fetchImages() {
      const payload = await axios.get(`${process.env.REACT_APP_REGISTRY_URL}/fetch/images`)
      setImages(payload.data)
      setIsLoading(false)
    }
    fetchImages()
  }, [])

  const history = useHistory();
  const handleRowClick = (id) => {
    history.push(`/image/${id}`);
  };

  const columns = [
    {
      dataField: "id",
      text: "ID ",
      sort: true,
      filter: textFilter()
    },
    {
      dataField: "name",
      text: "NAME ",
      sort: true,
      filter: textFilter()
    },
    {
      dataField: "",
      text: "",
      formatter: (cell, row, i) => <OverlayTrigger
        key={i}
        style={{ verticalAlign: 'middle' }}
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip({ text: `Go to Image` })}
      ><Button style={{ display: 'flex', whiteSpace: 'nowrap',  marginLeft: 'auto' }} onClick={() => handleRowClick(row.id)} variant="link"><DoubleRightIcon /></Button></OverlayTrigger>
    }
  ];


  return (
    <>
      <h2 style={{ marginBottom: '1rem' }}>Images</h2>
      {isLoading ?
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '200px' }}>
          <p>Loading images...</p>
          <Spinner animation="border" role="status">
            <span className="visually-hidden" style={{ color: 'black' }}>Loading...</span>
          </Spinner>
        </div> : <BootstrapTable
          keyField="id"
          bootstrap4
          data={images}
          columns={columns}
          pagination={paginationFactory({ sizePerPage: 15 })}
          filter={filterFactory()}
          //striped
          bordered={false}
          hover
        />}
    </>
  )
}


export default Images;
