

export const createConfigJson = (deployment) => {
  const retVal = {}
  const keys = ['resources', 'replicas', 'env_variables']
  keys.forEach((key) => {
    if (typeof deployment[key] == 'string') {
      retVal[key] = JSON.parse(deployment[key])
    } else {
      retVal[key] = deployment[key]
    }
  })
  const parsedVars = retVal.env_variables
  delete retVal.env_variables
  retVal.config = listToTree(parsedVars)
  return retVal
}


export const listToTree = (env_variables) => {
  const retVal = {}
  env_variables.forEach((env_variable) => {
    const parts = env_variable.name.split('/')
    let currentReference = retVal
    for (let i = 0; i < parts.length - 1; i++) {
      if (currentReference[parts[i]] === undefined) {
        currentReference[parts[i]] = {}
      }
      currentReference = currentReference[parts[i]]
    }
    currentReference[parts[parts.length - 1]] = env_variable.value
  })
  return retVal
}

export const evaluateStringFields = (deployment) => {
  return {
    ...deployment,
    pods: JSON.parse(deployment.pods),
    env_variables: JSON.parse(deployment.env_variables),
    resources: JSON.parse(deployment.resources),
  }
}