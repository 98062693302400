import React from "react";

function DoubleRightIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
    >
      <path d="M13 17L18 12 13 7"></path>
      <path d="M6 17L11 12 6 7"></path>
    </svg>
  );
}

export default DoubleRightIcon;