import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { createConfigJson } from "../util/deployment-util";
import { useParams, useLocation, useHistory } from 'react-router-dom';
import axios from "axios";

function LogDownloadPage({}) {

    const {name} = useParams()
    const history = useHistory()

    useEffect(() => {
        const start = async () => {
            const payload = await axios.get( `${process.env.REACT_APP_REGISTRY_URL}/queried_logs/${name}`);
            const blob = new Blob([payload.data.logs]);
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${payload.data.name}.txt`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            history.push('/')

        }
        start()
    }, []);

  return (
    <>
    </>
  );
}

export default LogDownloadPage;