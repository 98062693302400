import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DeploymentView from "../deployment-view/deployment-view";
import "./deployment.scss"

function SingleClusterPage() {
  let { id } = useParams();

  const [deployments, setDeployments] = useState(null)
  const [images, setImages] = useState({})
  const [cluster, setCluster] = useState(null)

  useEffect(() => {
    async function fetchClusters() {
      const imagePayload = await axios.get(`${process.env.REACT_APP_REGISTRY_URL}/fetch/images`)
      const images = {}
      imagePayload.data.forEach((image) => {
        images[image.name] = image
      })
      setImages(images)
    }
    fetchClusters()
  }, [])

  useEffect(() => {
    async function fetchImages() {
      const payload = await axios.get(`${process.env.REACT_APP_REGISTRY_URL}/fetch/cluster/${id}`)
      const cl = payload.data;
      const deployments = payload.data.deployments.map((deployment) => {
        return {
          ...deployment,
          pods: JSON.parse(deployment.pods),
          resources: JSON.parse(deployment.resources)
        }
      })
      setDeployments(deployments)
      setCluster(cl)
    }
    fetchImages()
  }, [id])

  return (
    <div className="deployment-wrapper">
      {deployments && <DeploymentView className="mb-4" images={images} cluster={cluster} deployments={deployments} />}
    </div>
  )
}

export default SingleClusterPage;
