import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Image from "../image/image";

function WorkerMonitoring({monitoredWorker}) {

  const [secondsElapsed, setSecondsElapsed] = useState(1);
  const checkAgainOnSeconds = 5
  const [trackedDeployment, setTrackedDeployment] = useState(null)

    useEffect(() => {
    const timer = setTimeout(async () => {
        console.log('tick')
        if (secondsElapsed % checkAgainOnSeconds == 0) {
            await getDeployment()
            setSecondsElapsed(1)
        } else {
            setSecondsElapsed(secondsElapsed + 1);
        }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [secondsElapsed]);

  const getDeployment = async () => {
        const payload = {
            cluster_info: monitoredWorker.cluster,
            command: {
                name: 'get-single-deployment-status',
                namespace: monitoredWorker.namespace,
                deployment_name: monitoredWorker.deployment.name,
            }}
      let reply = await axios.post(`${process.env.REACT_APP_REGISTRY_URL}/command/issue_single_deployment_status_command`, payload)
      console.log(reply)
      if (reply.data.error == false) {
        let deployment = reply.data.result
        if (deployment == undefined) {
          setTrackedDeployment(null)
        } else {
          setTrackedDeployment(deployment)
        }
        console.log(deployment)
      }
  }

  return (
    <div>
        <h2>Tracked worker info</h2>
        <p>Checking every <b>{checkAgainOnSeconds}</b> seconds on worker status.</p>
        <p>Time elapsed since previous check <b>{secondsElapsed}</b></p>
        {trackedDeployment != null
        ? <div>
            <p>Namespace: <b>{monitoredWorker.namespace}</b></p>
            <p>Name: <b>{monitoredWorker.deployment.name}</b></p>
            <p>Current image: <b>{trackedDeployment.image}</b></p>
            <h3>Pods</h3>
            <table>
                <tbody>
                    {trackedDeployment.pods.map((pod, index) => <tr key={index}>
                        <td><b>{pod.status}</b></td>
                        <td>{pod.name}</td>
                    </tr>)}
                </tbody>
            </table>
        </div>
        : <div>
            Nothing to track
        </div>} 
    </div>
  )
}

export default WorkerMonitoring;


