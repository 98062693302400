import axios from "axios";
import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { Accordion, Button, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";

import "./single-images.scss";
import BackIcon from "../svg-icons/back-icon";
import GitIcon from "../svg-icons/git-icon";

function SingleImage() {
  let { id } = useParams();
  const history = useHistory();
  const [gitTags, setGitTags] = useState({});
  const [image, setImage] = useState(null);
  const focusTag = "";

  const fetchImage = async (e) => {
    if (image === null) {
      const payload = await axios.get(
        `${process.env.REACT_APP_REGISTRY_URL}/fetch/image/${id}`
      );
      payload.data.tags = payload.data.tags.map((imageTag, index) => {
        if (imageTag.name.length == 8) {
          return {
            ...imageTag,
            commit: imageTag.name,
            index
          }
        } else {
          const parts = imageTag.name.split('-')
          return {
            ...imageTag,
            commit: parts[parts.length - 1],
            index
          }
        }
      })
      payload.data.tags.sort((a, b) => a.pushed < b.pushed ? 1: -1)
      setImage(payload.data);
      const gitMap = {};
      if (payload.data.git_repository != null) {
        payload.data.git_repository.tags.forEach((tag) => {
          gitMap[tag["commit"]] = {name: tag["name"], message: tag['message'] };
        });
        setGitTags(gitMap);
      }
      console.log(payload.data)
    }
  };

  useEffect(() => {
    fetchImage();
  }, [id]);

  const columns = [
    {
      dataField: "pushed",
      text: "DATE ",
      sort: true,
      filter: textFilter(),
      formatter: (cell, i) => (
        <span key={i}>{new Date(cell).toLocaleString()}</span>
      ),
    },
    {
      dataField: "name",
      text: "IMAGE TAG NAME ",
      sort: true,
      filter: textFilter(),
      formatter: (cell, i) => (
        <span key={i}>
          {focusTag === cell ? <b>{cell}</b> : <div>{cell}</div>}
        </span>
      ),
    },
    {
      dataField: "",
      text: "GIT TAG ",
      sort: true,
      filter: textFilter(),
      formatter: (cell, row, i) => (
        <span key={i}>
          {gitTags[row.commit] !== undefined ? gitTags[row.commit]['name'] : null}
        </span>
      ),
    },
    {
      dataField: "",
      text: "Message",
      sort: true,
      filter: textFilter(),
      formatter: (cell, row, i) => (
        <span key={i}>
          {gitTags[row.commit] !== undefined ? gitTags[row.commit]['message'] : null}
        </span>
      ),
    },
  ];

  const handleGoBack = () => {
    history.goBack();
  };



  return (
    <div className="single-image">
      <h2>
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Button className="back-btn" variant="light" onClick={handleGoBack}>
            <BackIcon /> Go back
          </Button>

          <div>
            IMAGE: <b>{image?.name}</b>
          </div>
        </span>
      </h2>
      <div>
        <Accordion className="urls-accordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header><GitIcon /> GIT Details</Accordion.Header>
            <Accordion.Body>
              {image?.git_repository ? (
                <div className="git-details">
                  <Row className="mt-2">
                    <Col sm={1}></Col>
                    <Col>
                      <p>
                        <b>Project name:</b>{" "}
                        {image?.git_repository?.project_name}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={1}></Col>
                    <Col>
                      <p>
                        <b>Project url:</b>{" "}
                        <a href={image?.git_repository?.repository_url}>
                          {image?.git_repository?.repository_url}
                        </a>
                      </p>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col sm={1}></Col>
                    <Col>
                      <p style={{marginBottom: 0}}>
                        <b>Image name:</b> {image?.name}
                      </p>
                    </Col>
                  </Row>
                </div>
              ) : (
                <b style={{ marginBottom: "1rem", display: "inline-block" }}>
                  No Git Data available for this image
                </b>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {/* {image?.git_repository ? (
          <div className="git-details">
            <Row className="mt-2">
              <Col sm={1}></Col>
              <Col>
                <p>
                  <b>Project name:</b> {image?.git_repository?.project_name}
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm={1}></Col>
              <Col>
                <p>
                  <b>Project url:</b>{" "}
                  <a href={image?.git_repository?.repository_url}>
                    {image?.git_repository?.repository_url}
                  </a>
                </p>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col sm={1}></Col>
              <Col>
                <p>
                  <b>Image name:</b> {image?.name}
                </p>
              </Col>
            </Row>
          </div>
        ) : (
          <b style={{ marginBottom: "1rem", display: "inline-block" }}>
            No Git Data available for this image
          </b>
        )} */}
        <br/>
        {image?.tags && image?.tags.length !== 0 ? (
          <BootstrapTable
            keyField="index"
            bootstrap4
            data={image.tags}
            columns={columns}
            pagination={paginationFactory({ sizePerPage: 15 })}
            filter={filterFactory()}
            //striped
            bordered={false}
            hover
          />
        ) : (
          <p>No tags available for this image</p>
        )}
      </div>
    </div>
  );
}

export default SingleImage;
