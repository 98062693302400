import { Checkbox } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, ButtonGroup, Col, FloatingLabel, Form, Row, Spinner } from "react-bootstrap";
import { BarLoader, RotateLoader } from "react-spinners";
import DeployIcon from "../svg-icons/deploy-icon";

function AdminPage() {

    const [clusters, setClusters] = useState([])
    const [newImageTag, setNewImageTag] = useState('')
    const [newImageFull, setNewImageFull] = useState('')
    const [result, setResult] = useState('')

    useEffect(() => {
        async function fetchClusters() {
            const payload = await axios.get(`${process.env.REACT_APP_REGISTRY_URL}/fetch/clusters_detailed`)
            payload.data.forEach((cluster) => {
                cluster.namespaces = [...new Set(cluster.deployments.map((deployment) => deployment.namespace))]
            })
            setClusters(payload.data)
        }
        fetchClusters()
    }, [])


    useEffect(() => {
        setNewImageFull(`datumsolutions/service-operator:${newImageTag}`)
    }, [newImageTag])

    //apps_api, namespace, deployment, new_image
    const onOperatorUpgrade = async () => {
        for (let cluster of clusters) {
            try {
                console.log(`Trying to upgrade: ${cluster.label}...`)
                const payload = {
                    cluster_info: {
                        label: cluster.label,
                        client_id: cluster.client_id,
                        environment: cluster.environment,
                        implementation_shortname: cluster.implementation_shortname
                    }, command: {
                        name: 'image-tag-change',
                        namespace: 'service-registry',
                        deployment: 'service-operator',
                        new_image: newImageFull
                    }}
                let reply = axios.post(`${process.env.REACT_APP_REGISTRY_URL}/command/issue_deploy_command`, payload)
                reply = await reply
                console.log(reply)
                console.log('Successfully upgraded')
            } catch(e) {
                console.log(`Failed to upgrade ${cluster.label}`)
                console.log(e)
            }
        }

    }

    const dockerCredentialScan = async () => {
        let finalResult = []
        for (let cluster of clusters) {
            try {
                console.log(`Trying to scan: ${cluster.label}...`)
                const payload = {
                    cluster_info: {
                        label: cluster.label,
                        client_id: cluster.client_id,
                        environment: cluster.environment,
                        implementation_shortname: cluster.implementation_shortname
                    }, command: { name: 'scrape-docker-credentials' }}
                let reply = axios.post(`${process.env.REACT_APP_REGISTRY_URL}/command/issue_docker_credential_scrape_command`, payload)
                reply = await reply
                const data = reply.data
                if (data.error) {
                    finalResult.push({
                        cluster: cluster.label,
                        credentials: [],
                        note: 'Not scraped'
                    })
                    console.log('Failed to scrape')
                } else {
                    finalResult.push({
                        cluster: cluster.label,
                        credentials: data.result,
                        note: 'Cluster scraped'
                    })
                    console.log('Successfully scraped')
                }
            } catch(e) {
                console.log(`Failed to scrape ${cluster.label}`)
                console.log(e)
            }
        }
        console.log(finalResult)
    }

    return (
        <div className="mb-5 deploy-page">
            { clusters.length !== 0
            && <div>
                <Row>
                    <Col className="align-self-end" sm={6}>
                        <h1>Docker credential scan</h1>
                    </Col>
                </Row>
                <Row className="mt-5 justify-content-center">
                    <Col className="justify-content-center d-flex" sm={6}>
                        <Button className="deploy-btn" style={{minWidth: '150px'}} onClick={dockerCredentialScan}><DeployIcon />Scan docker credentials</Button>
                    </Col>
                </Row>
                <Row className="mt-0 justify-content-center">
                    <Col className="align-self-end" sm={6}>
                        <h1>Operator Upgrade</h1>
                    </Col>
                </Row>
                <Row className="mt-0 justify-content-center">
                    <Col className="align-self-end" sm={6}>
                        <h4>New image tag</h4>
                        <Form.Control placeholder="New image tag" value={newImageTag} onChange={(event) => setNewImageTag(event.target.value)}/>
                    </Col>
                </Row>
                <Row className="mt-3 justify-content-center">
                    <Col className="align-self-end" sm={6}>
                        <h4>Will deploy</h4>
                        <p>{newImageFull ? newImageFull : <span style={{opacity: 0.5}}>No data</span>}</p>
                    </Col>
                </Row>
                <Row className="mt-5 justify-content-center">
                    <Col className="justify-content-center d-flex" sm={6}>
                        <Button className="deploy-btn" style={{minWidth: '150px'}} onClick={onOperatorUpgrade}><DeployIcon />DEPLOY </Button>
                    </Col>
                </Row>
                <Row className="mt-5 justify-content-center">
                    Check execution in developer console
                </Row>

            </div>}
        </div>
    )
}


export default AdminPage;
