import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";

function Image({ name, id, autoExpand, focusTag, index }) {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [image, setImage] = useState(null)
  // const [gitTags, setGitTags] = useState({})

  const history = useHistory();

  function handlePush() {
    history.push(`/image/${id}`);
  }

  useEffect(() => {
    onImageClick();
  }, [])

  const onImageClick = async (e) => {
    if (image == null) {
      const payload = await axios.get(`${process.env.REACT_APP_REGISTRY_URL}/fetch/image/${id}`)
      setImage(payload.data)
      const gitMap = {}
      if (payload.data.git_repository != null) {
        console.log(payload.data.git_repository)
        payload.data.git_repository.tags.forEach((tag) => {
          gitMap[tag['commit']] = tag['name']
        })
        // setGitTags(gitMap)
      }
    }
    setIsCollapsed(!isCollapsed)
  }

  return (
    <div className="mt-2 mb-2">
      <p><b>{index + 1}</b></p>
      <p>{id}</p>
      <p>{name}</p>
    </div>
  )
}


export default Image;
