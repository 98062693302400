import axios from "axios"
import { useEffect, useState } from "react"
import Checkbox from '@mui/material/Checkbox';
import { Button, Row } from "react-bootstrap";
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from "@mui/material";
import QueryResult from "../query-result/query-result";
import './queries.scss'
import DoubleDownIcon from "../svg-icons/double-down";


function Queries() {
  const [clusters, setClusters] = useState([])
  const [clustersChecked, setClustersChecked] = useState([])
  const [images, setImages] = useState([])
  const [image, setImage] = useState('')
  // const selectAllOption = { label: 'Select All' };
  let options = [...clusters];
  // const [options, setOptions] = useState([...clusters])
  const [result, setResult] = useState(null)
  useEffect(() => {
    async function fetchClusters() {
      const imagePayload = await axios.get(`${process.env.REACT_APP_REGISTRY_URL}/fetch/images`)
      const payload = await axios.get(`${process.env.REACT_APP_REGISTRY_URL}/fetch/clusters`)
      setImages(imagePayload.data)
      setClustersChecked(new Array(payload.data.length).fill(false))
      setClusters(payload.data)
    }
    fetchClusters()
  }, [])

  const [checked, setChecked] = useState(false)

  // const onCheckBoxChange = (checked_index, event) => {
  //   setClustersChecked(clustersChecked.map((bool, index) => index == checked_index ? event.target.checked : bool))
  // }

  const handleOptionSelect = (event, option) => {
    setChecked(!checked)
    setClustersChecked(clustersChecked.map((bool, index) => index === option.id - 1 ? event.target.checked : bool))
  };


  const onButtonClick = async () => {
    const payload = {}
    payload.clusters = clusters.filter((cluster, index) => clustersChecked[index])
    payload.image = image
    const reply = await axios.post(`${process.env.REACT_APP_REGISTRY_URL}/query/image`, payload)
    setResult(reply.data)
  }

  const [allChecked, setAllChecked] = useState(false)
  const [value, setValue] = useState([]);

  function handleSelectAll() {
    setClustersChecked(clustersChecked.map(() => true))
    setAllChecked(true);

  }
  function handleDeselectAll() {
    setClustersChecked(clustersChecked.map(() => false))
    setAllChecked(false);
    setValue([])
  }



  return (
    <>
      <h2 style={{ marginBottom: '1rem' }}>Queries</h2>
      {/* <h2>Select Clusters</h2> */}
      {/* <Table striped bordered hover>
        <thead>
          <tr>
            <th sm={1}><b></b></th>
            <th sm={1}><b>CLIENT ID</b></th>
            <th sm={2}><b>IMPL. SHORTNAME</b></th>
            <th sm={2}><b>ENVIRONMENT</b></th>
            <th><b>LABEL</b></th>
          </tr>
        </thead>
        <tbody>
          {clusters.map((cluster, index) => {
            return (
              <tr key={index} style={{ width: '50%' }} className="align-items-center">
                <td sm={1}><Checkbox onChange={(event) => { onCheckBoxChange(index, event) }} checked={clustersChecked[index]} /></td>
                <td sm={1}>{cluster.client_id}</td>
                <td sm={2}>{cluster.implementation_shortname}</td>
                <td sm={2}>{cluster.environment}</td>
                <td>{cluster.label}</td>
              </tr>
            )
          })}
        </tbody>
      </Table> */}

      <h4 style={{
        display: 'flex',
        alignItems: 'center'
      }}>Select Cluster {allChecked || value.length > 0 ?
        <Button variant="primary" size="sm" style={{ marginLeft: 'auto' }} onClick={handleDeselectAll}>Deselect all Clusters</Button> :
        <Button variant="light" size="sm" style={{ marginLeft: 'auto' }} onClick={handleSelectAll}>Select all</Button>}</h4>
      <Autocomplete
        multiple
        value={value}
        disabled={allChecked}
        className={`${allChecked ? 'allChecked' : ''}`}
        id="checkboxes-tags-demo"
        options={options}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        renderOption={(props, option, { selected }, index) => (
          <li {...props} style={{ pointerEvents: "none" }}>
            <Checkbox
              style={{ pointerEvents: "all" }}
              checked={selected}
              onChange={(event) => handleOptionSelect(event, option)}
            />
            {option.label}
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} label={`${allChecked ? 'ALL CLUSTER SELECTED' : 'cluster[:tag]'}`} className="multi-option-input" />
        )}
      />

      <h4 style={{ marginTop: '1rem' }}>Select Image</h4>
      <Row>
        <Autocomplete
          freeSolo
          value={image}
          onInputChange={(event, newValue) => {
            setImage(newValue)
          }}
          onChange={(event, newValue) => {
            setImage(newValue)
            // onButtonClick()
          }}
          disableClearable
          options={images.map((image) => image.name)}
          renderInput={(params) => <TextField {...params} label="image[:tag]" />}
        />
      </Row>
      <Row className="justify-content-center mt-4 mb-4">
        <Button className="submit-btn" style={{ width: 'auto', minWidth: '150px' }} onClick={onButtonClick}>SUBMIT <DoubleDownIcon /></Button>
      </Row>
      <QueryResult result={result} />
    </>
  )
}

export default Queries;