import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import "./deployment-view.scss";
import { Download, Recycle, CircleFill } from "react-bootstrap-icons";
import axios from "axios";
import { BarLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { createConfigJson } from "../util/deployment-util";
import { useHistory } from "react-router";
import BackIcon from "../svg-icons/back-icon";
import { Accordion, Button } from "react-bootstrap";
import UrlsIcon from "../svg-icons/urls-icon";

function DeploymentView({ deployments, images, cluster }) {
  // const [events, setEvents] = useState([])
  const history = useHistory();
  const [display, setDisplay] = useState(deployments);
  const [filterNamespace, ] = useState("");
  const [filterName, ] = useState("");
  const [isDownloadingLogs, setIsDownloadingLogs] = useState(false);
  const [downloadingName, setDownloadingName] = useState("");
  const [ingresses, setIngresses] = useState([]);

  // const handleChange = (event, setter) => { setter(event.target.value) }

  const renderTooltip = ({ text }) => <Tooltip>{text}</Tooltip>;

  useEffect(() => {
    const filteredDeployments = deployments.filter(
      (deployment) =>
        deployment.namespace.includes(filterNamespace) &&
        deployment.name.includes(filterName)
    );
    setDisplay(filteredDeployments);
  }, [filterNamespace, filterName, deployments]);

  useEffect(() => {
    if (cluster.ingresses !== undefined && cluster.ingresses !== null) {
      const parsed = JSON.parse(cluster.ingresses);
      setIngresses(parsed);
    }
  }, [cluster.ingresses]);

  const buildFileName = () => {
    let now = new Date();
    const dateString = `${now.getFullYear()}-${(now.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${now.getDate().toString().padStart(2, "0")} ${now
      .getHours()
      .toString()
      .padStart(2, "0")}-${now.getMinutes()}`;
    let fileName = `${cluster.label}-${dateString}`;
    if (filterNamespace !== "") {
      fileName = `${fileName}-namespace-${filterNamespace}`;
    }
    if (filterName !== "") {
      fileName = `${fileName}-name-${filterName}`;
    }
    return fileName;
  };

  const downloadCurrentSelection = () => {
    const headerLine = "Namespace, Name, Image, Replicas";
    const lines = display.map(
      (deployment, index) =>
        `${deployment.namespace}, ${deployment.name}, ${deployment.image}, ${deployment.replicas}`
    );
    const resArray = [headerLine, ...lines];
    const resString = resArray.join("\n");
    const blob = new Blob([resString]);
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${buildFileName()}.csv`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const downloadConfig = (deployment) => {
    const parsed = createConfigJson(deployment);
    const formatted = JSON.stringify(parsed, null, 2);
    const blob = new Blob([formatted]);
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${deployment.name}-config.json`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const downloadLogs = async (namespace, name) => {
    setIsDownloadingLogs(true);
    toast.info("Downloading logs...");
    setDownloadingName(name);
    const payload = {
      cluster_info: cluster,
      command: {
        name: "get-deployment-logs",
        deployment: name,
        namespace: namespace,
      },
    };
    try {
      const reply = await axios.post(
        `${process.env.REACT_APP_REGISTRY_URL}/command/issue_log_command`,
        payload
      );
      if (reply.status === 200) {
        const logs = reply.data.result;
        if (reply.data.result === "Command not executed in time") {
          throw "Logs not found or not supported on this environment";
        }
        console.log(reply.data);

        let i = 0;
        for (let log of logs) {
          const blob = new Blob([log.log]);
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${log.pod}.txt`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          i++
          if (i % 5 == 0) {
            await new Promise(r => setTimeout(r, 2000));
          }

        }

        if (logs.length === 0) {
          toast("No logs available for this service");
        }
      } else {
        toast("Failed to retrieve logs");
      }
    } catch (e) {
      toast(`${e}`);
    } finally {
      setIsDownloadingLogs(false);
    }
  };

  const determineStatusColor = (status) => {
    if (status === "Running") {
      return "green";
    } else if (status === "Error" || status === "Failed") {
      return "red";
    } else {
      return "blue";
    }
  };

  const handleGoBack = () => {
    history.goBack();
  };


  const sortOrder = ["prod", "preprod", "test", "tst", "dev"];

  const namespaces = display.reduce((obj, { namespace }) => {
    obj[namespace] = namespace;
    return obj;
  }, {});

  const orderedNamespacesArray = Object.keys(namespaces).sort((a, b) => {
    const aIndex = sortOrder.findIndex((keyword) => a.includes(keyword));
    const bIndex = sortOrder.findIndex((keyword) => b.includes(keyword));

    if (aIndex !== -1 && bIndex !== -1) {
      return aIndex - bIndex;
    }

    if (aIndex !== -1) {
      return -1;
    }
    if (bIndex !== -1) {
      return 1;
    }
    return 0;
  });
  
  // const orderedNamespacesMap = {};
  // orderedNamespaces.forEach((namespace) => {
  //   orderedNamespacesMap[namespace] = namespaces[namespace];
  // });

  // const orderedNamespacesObject = orderedNamespaces.reduce((obj, namespace) => {
  //   obj[namespace] = namespace;
  //   return obj;
  // }, {});

  const sortedNamespaces = Object.entries(namespaces)
  .sort(([aKey, aValue], [bKey, bValue]) => {
    const aIndex = sortOrder.findIndex(keyword => aValue.includes(keyword));
    const bIndex = sortOrder.findIndex(keyword => bValue.includes(keyword));
    if (aIndex !== -1 && bIndex !== -1) {
      return aIndex - bIndex;
    }
    if (aIndex !== -1) {
      return -1;
    }
    if (bIndex !== -1) {
      return 1;
    }
    return 0;
  })
  .reduce((obj, [key, value]) => {
    obj[key] = value;
    return obj;
  }, {});

  const columns = [
    {
      dataField: "namespace",
      text: "NAMESPACE ",
      sort: true,
      formatter: (cell, i) => <span key={i}>{namespaces[cell]}</span>,
      filter: selectFilter({
        options: sortedNamespaces,
        placeholder: "All namespaces",
        defaultValue: orderedNamespacesArray[0],
      })
    },
    {
      text: "NAME ",
      sort: true,
      dataField: "name",
      filter: textFilter(),
      formatter: (cell, rowObject, i) => 
      <span key={i}>
        <a href={`/single-deployment-history/${encodeURIComponent(cluster.label)}/${rowObject.namespace}/${rowObject.name}`}>{cell}</a>
      </span>
    },
    {
      dataField: "image",
      text: "IMAGE ",
      sort: false,
      filter: textFilter(),
      formatter: (cell, i) =>
      <span key={i}>
        {cell.split(":")[0] in images ? (
          <a
            key={i + 1}
            href={`/image/${images[cell.split(":")[0]].id}/${
              cell.split(":")[1]
            }`}
          >
            {cell}
          </a>
        ) : (
          <div key={i + 1} style={{ wordBreak: "break-word" }}>
            {cell}
          </div>
        )}
      </span>
    },
    {
      key: 'requests',
      dataField: "requests",
      text: "Requests",
      sort: true,
      filter: textFilter(),
      formatter: (cell,row, i) => {
        return (
          <div key={`requests-${i}`}>
            <span>
              <b>CPU: </b>
              <span>
                {row?.resources?.requests?.cpu ? row?.resources?.requests?.cpu : '-'}
              </span>
            </span>
            <br />
            <span>
              <b>Memory: </b>
              <span>
                {row?.resources?.requests?.memory ? row?.resources?.requests?.memory : "-"}
              </span>
            </span>
          </div>
        );
      },
    },
    {
      key: 'limits',
      dataField: "limits",
      text: "Limits",
      sort: true,
      filter: textFilter(),
      formatter: (cell,row, i) => {
        return (
          <div key={`limits-${i}`}>
            <span>
              <b>CPU: </b>
              <span>
                {row?.resources?.limits?.cpu ? row?.resources?.limits?.cpu : '-'}
              </span>
            </span>
            <br />
            <span>
              <b>Memory: </b>
              <span>
                {row?.resources?.limits?.memory ? row?.resources?.limits?.memory : "-"}
              </span>
            </span>
          </div>
        );
      },
    },
    {
      dataField: "pods",
      text: <div style={{ textAlign: "center" }}>PODS</div>,
      formatter: (cell, index) =>
        <span key={index} style={{
          maxWidth: '150px',
          display: 'flex',
          alignItems: 'center',
          flexWrap: "wrap",
          justifyContent: 'center'
        }}>
          {cell.map((pod, i) => (
            <span key={i + 1}>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip({
                text: `Status: ${pod.status}, Name: ${pod.name}`
              })}
            >
              <div className="pod-icon-wrap"><CircleFill color={determineStatusColor(pod.status)} /><small>{i + 1}</small></div>
            </OverlayTrigger>
            </span>
          ))}
        </span>
    },
    {
      dataField: "logs",
      text: <div style={{ textAlign: "center" }}>LOGS</div>,
      formatter: (cell, row, i) => (
        <div key={i}>
          {isDownloadingLogs ? (
            <>
              {downloadingName === row.name ? (
                <p key={i}>
                  Downloading... <BarLoader></BarLoader>
                </p>
              ) : (
                <></>
              )}
            </>
          ) : (
            <OverlayTrigger
              key={i}
              placement="top"
              overlay={renderTooltip({ text: `Download ${row.name} logs` })}
            >
              <Download
                key={i}
                style={{ cursor: "pointer" }}
                size={25}
                className="align-baseline"
                onClick={() => {
                  downloadLogs(row.namespace, row.name);
                }}
              />
            </OverlayTrigger>
          )}
        </div>
      ),
    },
    {
      dataField: "configuration",
      text: <div style={{ textAlign: "center" }}>CONFIG</div>,
      formatter: (cell, row, i) => (
        <span key={i}>
          <OverlayTrigger
            placement="top"
            overlay={renderTooltip({ text: `Download ${row.name} config` })}
          >
            <Recycle
              style={{
                cursor: "pointer",
                textAlign: "center",
                verticalAlign: "middle",
              }}
              size={25}
              className="align-baseline"
              onClick={() => {
                downloadConfig(row);
              }}
            />
          </OverlayTrigger>
        </span>
      ),
    },
  ];

  return (
    <>
      <h2>
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Button className="back-btn" variant="light" onClick={handleGoBack}>
            <BackIcon /> Go back
          </Button>

          <div>
            CLUSTER: <b>{cluster?.label}</b>
          </div>
        </span>
        <span onClick={downloadCurrentSelection}>
          <Download label="Download all" size={25} className="align-baseline" />
          <small>Generate Report (CSV)</small>
        </span>
      </h2>
      <ToastContainer hideProgressBar />
      {ingresses?.length > 0 && 
        <Accordion className="urls-accordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <UrlsIcon /> Environment URLs
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                {ingresses.map((ingress, i) => {
                  return (
                    <li key={i}>
                      {ingress.type} <a href={`https://${ingress.host}`}>{ingress.host}</a>
                    </li>
                  );
                })}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      }

      {ingresses?.length !== 0 && <br />}

      <BootstrapTable
        keyField={"display"}
        bootstrap4
        data={display}
        columns={columns}
        pagination={paginationFactory({ sizePerPage: 15 })}
        filter={filterFactory()}
        //striped
        bordered={false}
        hover
      />
    </>
  );
}

export default DeploymentView;