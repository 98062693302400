import { Card, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import ClusterIcon from "../svg-icons/cluster-icon";
import CompareIcon from "../svg-icons/compare-icon";
import ImagesIcon from "../svg-icons/images-icon";
import QueriesIcon from "../svg-icons/queries-icon";
import DeployIcon from "../svg-icons/deploy-icon";
import HistoryIcon from "../svg-icons/history-icon";
import ValidationIcon from "../svg-icons/validation-icon";
import PrivateRoute from "../private-route/private-route";

import "./home.scss"
import RightArrowIcon from "../svg-icons/right-arrow-icon";
import QueueIcon from "../svg-icons/queue-icon";

function Home() {
  const history = useHistory();
  const navigate = (route) => {
    history.push(route);
  }

  return (
    <Row>
      <h2 style={{ marginBottom: '1rem' }}>Service Registry</h2>
      <Row className="card-wrapper">
        <Card onClick={() => navigate('/clusters')}>
          <span className="card-icon cluster-icon">
            <ClusterIcon />
          </span>
          <Card.Body>
            <Card.Title>Clusters</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">Efficient Service Discovery and Management</Card.Subtitle>
            <Card.Text>
              Clusters are a powerful tool for managing services in a distributed system. At its core, a cluster is a group of machines that work together to provide a service. In a distributed system, the machines in a cluster can be spread across different regions, data centers, or even cloud providers.
            </Card.Text>
            <Card.Link href="/clusters">Go to Clusters <RightArrowIcon /></Card.Link>
          </Card.Body>
        </Card>
        <Card onClick={() => navigate('/images')}>
          <span className="card-icon images-icon">
            <ImagesIcon />
          </span>
          <Card.Body>
            <Card.Title>Images</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">Efficient Containerization and Deployment</Card.Subtitle>
            <Card.Text>
              Images are a fundamental building block of containerization and enable efficient and consistent deployment of software across different environments. They allow developers to package an application with all of its dependencies, making it easy to move between development, testing, and production environments.
            </Card.Text>
            <Card.Link href="/images">Go to Images <RightArrowIcon /></Card.Link>
          </Card.Body>
        </Card>
        <Card onClick={() => navigate('/queries')}>
          <span className="card-icon queries-icon">
            <QueriesIcon />
          </span>
          <Card.Body>
            <Card.Title>Queries</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">Powerful Data Retrieval and Analysis</Card.Subtitle>
            <Card.Text>
              Queries enable powerful data retrieval and analysis by allowing developers and data analysts to filter, sort, and aggregate data in a flexible and efficient way. This makes it easier to extract insights from large datasets and drive data-driven decision-making.
            </Card.Text>
            <Card.Link href="/queries">Go to Queries <RightArrowIcon /></Card.Link>
          </Card.Body>
        </Card>
        <Card onClick={() => navigate('/environment-comparisson')}>
          <span className="card-icon comparassion-icon">
            <CompareIcon />
          </span>
          <Card.Body>
            <Card.Title>Environment comparisson</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">Streamlined Development and Deployment</Card.Subtitle>
            <Card.Text>
              Environment comparison tools enable developers to quickly and easily compare different environments and identify differences in configuration and behavior. This makes it easier to streamline development and deployment processes and ensure consistency between different environments.
            </Card.Text>
            <Card.Link href="/environment-comparisson">Go to Environment Comparassion <RightArrowIcon /></Card.Link>
          </Card.Body>
        </Card>
        <Card onClick={() => navigate('/validation')}>
          <span className="card-icon validation-icon">
          <ValidationIcon />
          </span>
          <Card.Body>
            <Card.Title>Validation</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">Streamlined Development and Deployment</Card.Subtitle>
            <Card.Text>
            Validation tools enable developers and quality assurance teams to test software and ensure that it meets specific requirements and standards. They help identify bugs and errors early in the development process and prevent them from becoming larger problems later on. Effective validation is crucial for ensuring the quality and reliability of software products.
            </Card.Text>
            <Card.Link href="/validation">Go to Validation <RightArrowIcon /></Card.Link>
          </Card.Body>
        </Card>
        <PrivateRoute roles={['DEVOPS']}>
        <Card onClick={() => navigate('/deploy-page')}>
          <span className="card-icon deploy-icon">
            <DeployIcon />
          </span>
          <Card.Body>
            <Card.Title>Deploy</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">Efficient Deployment and Release Management</Card.Subtitle>
            <Card.Text>
            Deployment tools automate the process of deploying software to different environments, making it easier and more efficient to release new features and updates. They enable developers to deploy code more quickly and reliably, with less risk of errors or downtime. Effective deployment and release management is crucial for ensuring that software products are delivered to users in a timely and reliable manner.</Card.Text>
            <Card.Link href="/deploy-page">Go to Deploy <RightArrowIcon /></Card.Link>
          </Card.Body>
        </Card>
        </PrivateRoute>
        <Card onClick={() => navigate('/history-view')}>
          <span className="card-icon history-icon">
            <HistoryIcon/>
          </span>
          <Card.Body>
            <Card.Title>History</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">Comprehensive Version Control and Auditing</Card.Subtitle>
            <Card.Text>
            History tools provide a comprehensive record of changes to software code over time, enabling developers to track changes and revert to previous versions if necessary. They also enable auditing of changes and can be used to document compliance with regulatory and security requirements. Comprehensive version control and auditing are essential for managing the development and maintenance of complex software systems.</Card.Text>
            <Card.Link href="/history-view">Go to History <RightArrowIcon /></Card.Link>
          </Card.Body>
        </Card>
        <Card onClick={() => navigate('/queue-report-page')}>
          <span className="card-icon queue-icon">
            <QueueIcon/>
          </span>
          <Card.Body>
            <Card.Title>Queue Status</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">Queue Status Monitoring and Optimization</Card.Subtitle>
            <Card.Text>
            Queue Status is a comprehensive tool that enables efficient monitoring of queues in a distributed system. It provides real-time insights into the status and performance of queues, allowing system administrators and developers to track and analyze queue metrics such as size, throughput, and latency. With Queue Status, you can proactively identify bottlenecks and optimize the queuing system to ensure efficient resource utilization and smooth operation of services. Whether you are managing message queues, task queues, or job queues, Queue Status empowers you to monitor, analyze, and fine-tune queues to maximize system performance and deliver a seamless user experience.</Card.Text>
            <Card.Link href="/queue-report-page">Go to Queue status <RightArrowIcon /></Card.Link>
          </Card.Body>
        </Card>
      </Row>
    </Row>
  )
}
export default Home;
