import React from "react";

function QueriesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
    >
      <path stroke="none" d="M0 0h24v24H0z"></path>
      <path d="M17.73 19.875A2.225 2.225 0 0115.782 21H8.499a2.222 2.222 0 01-1.947-1.158l-4.272-6.75a2.269 2.269 0 010-2.184l4.272-6.75A2.225 2.225 0 018.498 3h7.285c.809 0 1.554.443 1.947 1.158l3.98 6.75a2.33 2.33 0 010 2.25l-3.98 6.75v-.033z"></path>
      <path d="M8 11.5a3.5 3.5 0 107 0 3.5 3.5 0 10-7 0M14 14l2 2"></path>
    </svg>
  );
}

export default QueriesIcon