import { Checkbox } from "@mui/material";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import { useEffect, useState } from "react";
import {
  Button,
  FloatingLabel,
  Form,
  Spinner,
} from "react-bootstrap";
import ComparissonResult from "../comparisson-result/comparisson-result";
import DoubleDownIcon from "../svg-icons/double-down";
import {
  createConfigJson,
  evaluateStringFields,
} from "../util/deployment-util";
import "./cluster-comparisson-page.scss";

import { useParams, useLocation } from 'react-router-dom';

function ClusterComparissonPage() {
  const [clusters, setClusters] = useState([]);
  const [cluster1, setCluster1] = useState("");
  const [cluster2, setCluster2] = useState("");

  const [namespace1, setNamespace1] = useState("");
  const [namespace2, setNamespace2] = useState("");

  const [cluster1Id, setCluster1Id] = useState(0);
  const [cluster2Id, setCluster2Id] = useState(0);

  const urlParams = useParams();

  const [ignoreEmpty, setIgnoreEmpty] = useState(true);
  const [isCompareReplicaCount, setIsCompareReplicaCount] = useState(true);
  const [isCompareResources, setIsCompareResources] = useState(true);
  const [isCompareConfig, setIsCompareConfig] = useState(false);
  const [comparissonResults, setComparissonResults] = useState([]);

  useEffect(() => {
    console.log('USE EFFECT')
    async function fetchClusters() {
      const payload = await axios.get(
        `${process.env.REACT_APP_REGISTRY_URL}/fetch/clusters_detailed`
      );
      payload.data.forEach((cluster) => {
        cluster.namespaces = [
          ...new Set(
            cluster.deployments.map((deployment) => deployment.namespace)
          ),
        ];
      });

      payload.data.sort((a, b) => {return a.label > b.label ? 1 : -1})
      setClusters(payload.data);

      if (Object.keys(urlParams).length !== 0) {
        reuseUrlParams()
      }



      //setCluster1(0); //index 0
      // setNamespace1(payload.data[0].namespaces[0]); //actual namespace
      //setCluster2(0); //index 0
      // setNamespace2(payload.data[0].namespaces[0]); //actual namespace
    }
    fetchClusters();
  }, []);


  const reuseUrlParams = () => {
    const {cluster1, namespace1, cluster2, namespace2} = urlParams
    if (cluster1 == undefined || cluster2 == undefined || namespace1 == undefined || namespace2 == undefined) {
      return
    }
    setCluster1(cluster1)
    setCluster2(cluster2)
    setNamespace1(namespace1)
    setNamespace2(namespace2)
  }

  const createComparissonObject = (deployment) => {
    const retVal = { name: deployment.name };

    let version = deployment.image.split(":")[1];
    version = version === undefined ? "latest" : version;
    retVal.version = version;

    const configJson = createConfigJson(deployment);

    if (isCompareConfig) {
      retVal.config = configJson.config;
    }
    if (isCompareReplicaCount) {
      retVal.replicas = configJson.replicas;
    }
    if (isCompareResources) {
      retVal.resources = configJson.resources;
    }

    return retVal;
  };

  const onCompare = () => {
    let env1Deployments = [];
    let env2Deployments = [];
    if (!ignoreEmpty) {
      env1Deployments = clusters[cluster1].deployments.filter(
        (deployment) => deployment.namespace === namespace1
      );
      env2Deployments = clusters[cluster2].deployments.filter(
        (deployment) => deployment.namespace === namespace2
      );
    } else {
      env1Deployments = clusters[cluster1].deployments.filter(
        (deployment) =>
          deployment.namespace === namespace1 && deployment.replicas !== 0
      );
      env2Deployments = clusters[cluster2].deployments.filter(
        (deployment) =>
          deployment.namespace === namespace2 && deployment.replicas !== 0
      );
    }
    const comparissonResults = {};

    env1Deployments = env1Deployments
      .map((deployment) => evaluateStringFields(deployment))
      .map((deployment) => createComparissonObject(deployment));
    env2Deployments = env2Deployments
      .map((deployment) => evaluateStringFields(deployment))
      .map((deployment) => createComparissonObject(deployment));

    env1Deployments.forEach((deployment) => {
      comparissonResults[deployment.name] = {};
      comparissonResults[deployment.name]["env1"] = { ...deployment };
      comparissonResults[deployment.name]["env2"] = { version: "None" };
    });

    env2Deployments.forEach((deployment) => {
      if (!(deployment.name in comparissonResults)) {
        comparissonResults[deployment.name] = {};
        comparissonResults[deployment.name]["env1"] = { version: "None" };
      }
      comparissonResults[deployment.name]["env2"] = { ...deployment };
    });

    const comparissonResultList = [];
    for (let deplyomentName in comparissonResults) {
      const comparissonResult = comparissonResults[deplyomentName];
      comparissonResultList.push({
        deployment: deplyomentName,
        ...comparissonResult,
      });
    }
    window.history.pushState('compare', 'compare', `/environment-comparisson/${cluster1}/${namespace1}/${cluster2}/${namespace2}`);

    setComparissonResults(comparissonResultList);

    // Navigate to the new URL with the query parameters
    // cluster1 && cluster2 && namespace1 && namespace2 && window.open(`/comparisson/${cluster1}/${namespace1}/${cluster2}/${namespace2}`, '_blank');
  };


  return (
    <div className="mb-5">
      <h2
        style={{ marginBottom: "1rem", display: "flex", alignItems: "center" }}
      >
        {clusters && clusters.length === 0 && (
          <Spinner
            style={{ marginRight: "7px" }}
            animation="grow"
            variant="primary"
          />
        )}{" "}
        Env. Comparisson
      </h2>
      <div className="compare-wrapper">
        <div className="first-env">
          <h4>First environment</h4>
          <FloatingLabel label="Cluster 1">
          <Form.Select
            value={cluster1}
            placeholder="Select cluster 1"
            onChange={(e) => {
              const selectedValue = e.target.value === "choose" ? "" : e.target.value;
              setCluster1(selectedValue);
              setNamespace1(clusters[selectedValue]?.namespaces[0]);
              setCluster1Id(clusters[selectedValue]?.id);
            }}
          >
            <option disabled value="" defaultValue="">
              Select cluster 1
            </option>
            {clusters
              .map((cluster, key) => (
                <option key={key} value={key}>
                  {cluster.label}
                </option>
              ))}
          </Form.Select>
          </FloatingLabel>
          <FloatingLabel label="Namespace 1">
            <Form.Select
              disabled={cluster1Id === 0}
              value={namespace1}
              onChange={(e) => {
                setNamespace1(e.target.value);
              }}
            >
              {cluster1 === "" ? (
                <></>
              ) : (
                <>
                  {clusters[cluster1].namespaces
                    .map((namespace, key) => {
                      return (
                        <option key={key} value={namespace}>
                          {namespace}
                        </option>
                      );
                    })}
                </>
              )}
            </Form.Select>
          </FloatingLabel>
        </div>
        <div className="second-env">
          <h4>Second environment</h4>
          <FloatingLabel label="Cluster 2">
            <Form.Select
              disabled={clusters && clusters.length === 0}
              value={cluster2}
              placeholder="Select cluster 2"
              onChange={(e) => {
                setCluster2(e.target.value);
                setNamespace2(clusters[e.target.value].namespaces[0]);
                setCluster2Id(clusters[e.target.value].id)
              }}
            >
            <option disabled value="" defaultValue="">
              Select cluster 2
            </option>
              {clusters
                .map((cluster, key) => {
                  return (
                    <option key={key} value={key}>
                      {cluster.label}
                    </option>
                  );
                })}
            </Form.Select>
          </FloatingLabel>
          <FloatingLabel label="Namespace 2">
            <Form.Select
              disabled={cluster2Id === 0}
              value={namespace2}
              onChange={(e) => {
                setNamespace2(e.target.value);
              }}
            >
              {cluster2 === "" ? (
                <></>
              ) : (
                <>
                  {clusters[cluster2].namespaces
                    .map((namespace, key) => {
                      return (
                        <option key={key} value={namespace}>
                          {namespace}
                        </option>
                      );
                    })}
                </>
              )}
            </Form.Select>
          </FloatingLabel>
        </div>
      </div>
      <div className="additional-options">
        <h4>Additional options</h4>
        <div className="additional-options-checkboxes">
          <div className="checks-wrapper">
            <Checkbox
              checked={ignoreEmpty}
              onChange={(event) => {
                setIgnoreEmpty(event.target.checked);
              }}
            />
            <label>Ignore deployments with 0 replicas</label>
          </div>
          <div className="checks-wrapper">
            <Checkbox
              checked={isCompareReplicaCount}
              onChange={(event) => {
                setIsCompareReplicaCount(event.target.checked);
              }}
            />
            <label>Compare replica count</label>
          </div>
          <div className="checks-wrapper">
            <Checkbox
              checked={isCompareResources}
              onChange={(event) => {
                setIsCompareResources(event.target.checked);
              }}
            />
            <label>Compare resources</label>
          </div>
          <div className="checks-wrapper">
            <Checkbox
              checked={isCompareConfig}
              onChange={(event) => {
                setIsCompareConfig(event.target.checked);
              }}
            />
            <label>Compare config</label>
          </div>
        </div>
      </div>
      <div className="compare-btn">
        <Button onClick={onCompare} style={{ minWidth: "150px" }} disabled={!namespace1?.length > 0 || !namespace2?.length > 0}>
          COMPARE <DoubleDownIcon />
        </Button>
      </div>
      {comparissonResults &&
        comparissonResults.length > 0 &&
        clusters.length !== 0 && (
          <h4 className="mt-4 mb-4">Comparisson results</h4>
        )}
      {comparissonResults &&
      comparissonResults.length > 0 &&
      clusters.length !== 0 ? (
        <ComparissonResult
          clusters={[clusters[cluster1], clusters[cluster2]]}
          comparissonList={comparissonResults}
          environment1={namespace1}
          environment2={namespace2}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default ClusterComparissonPage;
