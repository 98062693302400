import axios from "axios";
import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import { useHistory } from "react-router";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import DoubleRightIcon from "../svg-icons/double-right-icon";

function Clusters() {
  const history = useHistory();
  const [clusters, setClusters] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const selectOptions = {
    nonprod: "nonprod",
    prod: "prod"
  };

  const renderTooltip = ({ text }) => (<Tooltip>{text}</Tooltip>);

  useEffect(() => {
    async function fetchClusters() {
      try {
        // const imagePayload = await axios.get(`${process.env.REACT_APP_REGISTRY_URL}/fetch/images`)
        const payload = await axios.get(`${process.env.REACT_APP_REGISTRY_URL}/fetch/clusters`);
        // const images = {}
        // imagePayload?.data.forEach((image) => {
        //   images[image.name] = image
        // });
        setClusters(payload.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching clusters:", error);
      }
    }
    fetchClusters();
  }, []);

  const handleRowClick = (id) => {
    history.push(`/cluster/${id}`);
  };

  const columns = [
    {
      dataField: "client_id",
      text: "CLIENT ID",
      sort: true,
      filter: textFilter()
    },
    {
      dataField: "implementation_shortname",
      text: "IMPL. SHORTNAME",
      sort: true,
      filter: textFilter()
    },
    {
      dataField: "environment",
      text: "ENVIRONMENT",
      formatter: cell => selectOptions[cell],
      filter: selectFilter({
        options: selectOptions
      })
    },
    {
      dataField: "label",
      text: "LABEL",
      sort: true,
      filter: textFilter()
    },
    {
      dataField: "",
      text: "",
      formatter: (cell, row, i) => (
        <OverlayTrigger
          key={i}
          style={{ verticalAlign: 'middle' }}
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip({ text: `Go to Cluster` })}
        >
          <Button style={{ display: 'flex', whiteSpace: 'nowrap', marginLeft: 'auto' }} onClick={() => handleRowClick(row.id)} variant="link">
            <DoubleRightIcon />
          </Button>
        </OverlayTrigger>
      )
    }
  ];

  return (
    <>
      <h2 style={{ marginBottom: '1rem' }}>Clusters List</h2>
      {isLoading ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '200px' }}>
          <p>Loading clusters...</p>
          <Spinner animation="border" role="status">
            <span className="visually-hidden" style={{ color: 'black' }}>Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="bootstrap-table-wrapper">
          <BootstrapTable
            keyField="id"
            bootstrap4
            data={clusters}
            columns={columns}
            pagination={paginationFactory({ sizePerPage: 15 })}
            filter={filterFactory()}
            bordered={false}
            hover
          />
        </div>
      )}
    </>
  );
}

export default Clusters;