import "./log-query-page.scss";
import { Checkbox, FormControlLabel } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  FloatingLabel,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
} from "react-bootstrap";
import { Download, Share } from "react-bootstrap-icons";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { BarLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";
import Tooltip from "react-bootstrap/Tooltip";
import DoubleDownIcon from "../svg-icons/double-down";
import { useAuth } from "react-oidc-context";

function LogQueryPage() {
  const [clusters, setClusters] = useState([]);
  const [selectedCluster, setSelectedCluster] = useState(null);
  const [searchId, setSearchId] = useState("");
  const [waitingForCommand, setWaitingForCOmmand] = useState(false);
  const [commandResult, setCommandResult] = useState("");
  const [previousQueries, setPreviousQueries] = useState([]);
  const [filteredQueries, setFilteredQueries] = useState([]);
  const [secondsSinceLastQuery, setSecondsSinceLastQuery] = useState(0);
  const [phases, setPhases] = useState([
    { checked: false, regexp: "ingest", label: "Ingestion" },
    { checked: false, regexp: "core", label: "Core" },
    { checked: false, regexp: "ocr|textract", label: "OCR" },
    { checked: false, regexp: "extract", label: "Extraction" },
    { checked: false, regexp: "rest", label: "Rest API" },
    { checked: false, regexp: "normaliz", label: "Normalization" },
    { checked: false, regexp: "export", label: "Export" },
    { checked: false, regexp: "retention", label: "Retention" },
    { checked: false, regexp: "recon", label: "Reconciliation" },
    { checked: false, regexp: "report", label: "Reporting" },
  ]);
  const [unixTimestampSinceOpened, setUnixTimestampSinceOpened] = useState(
    Math.floor(Date.now() / 1000)
  );

  const [batchIdValid, setBatchIdValid] = useState(false);
  const guidRegex =
    /^[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12}$/;

  const [showOldQueries, setShowOldQueries] = useState(false);
  const [showPersonalQueriesOnly, setShowPersonalQueriesOnly] = useState(true);

  const auth = useAuth()
  const userEmail = auth.user.profile.email

  const [showPhaseFilter, setShowPhaseFilter] = useState(false);
  const logLevels = ["Normal", "Detailed"];
  const [selectedLogLevel, setLogLevel] = useState("Detailed");

  const idSearchOptions = [
    {
      value: "batchId",
      label: "Batch ID",
    },
    {
      value: "documentId",
      label: "Document ID",
    },
  ];

  const [selectedIdSearchOption, setSelectedIdSearchOption] = useState(
    idSearchOptions[0]
  );

  useEffect(() => {
    async function fetchClusters() {
      const payload = await axios.get(
        `${process.env.REACT_APP_REGISTRY_URL}/fetch/clusters`
      );
      const filtered = payload.data.filter(
        (cluster) =>
          cluster.additional_info &&
          cluster.additional_info.toLowerCase().includes("loki_labels")
      );
      const formatted = filtered.map((cluster) => {
        return {
          ...cluster,
          additional_info: JSON.parse(cluster.additional_info),
        };
      });
      setClusters(formatted);
      setSelectedCluster(formatted[0]);
    }
    fetchClusters();
  }, []);

  const fillResultTable = async () => {
    const namesPayload = await axios.get(
      `${process.env.REACT_APP_REGISTRY_URL}/fetch/log_file_names`
    );
    const names = namesPayload.data;
    let readyDownloads = names.map(({ name, userEmail }) => {
      const parts = name.split("-");
      const unixTimestamp = Number(parts[0]);
      const isNew = unixTimestamp > unixTimestampSinceOpened;
      const date = new Date(unixTimestamp * 1000).toLocaleString();
      const batchId = parts.slice(1).join("-");
      return {
        nameDownload: name,
        nameShare: name,
        date,
        userEmail,
        batchId,
        unixTimestamp,
        isNew,
      };
    });
    readyDownloads.sort((a, b) => b.unixTimestamp - a.unixTimestamp);
    setPreviousQueries(readyDownloads);
    setSecondsSinceLastQuery(1);
  };

  useEffect(() => {
    let toDisplay = previousQueries;
    if (!showOldQueries) {
      toDisplay = toDisplay.filter((a) => a.isNew);
    }
    if (showPersonalQueriesOnly) {
      toDisplay = toDisplay.filter(
        (entry) => entry.userEmail == userEmail
      );
    }
    setFilteredQueries(toDisplay);
  }, [previousQueries, showOldQueries, showPersonalQueriesOnly]);

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (secondsSinceLastQuery % 10 == 0) {
        fillResultTable();
      } else {
        setSecondsSinceLastQuery(secondsSinceLastQuery + 1);
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [secondsSinceLastQuery, showOldQueries]);

  const onCheckBoxChange = (event, setter) => {
    setter(event.target.checked);
  };

  const onFilterPhase = (event, selectedIndex) => {
    setPhases(
      phases.map((phase, currentIndex) => {
        if (currentIndex == selectedIndex) {
          return {
            ...phase,
            checked: event.target.checked,
          };
        } else {
          return phase;
        }
      })
    );
  };

  const onQuery = async () => {
    let query = `{${selectedIdSearchOption.value}="${searchId}"`;
    if (showPhaseFilter) {
      console.log("IS SHOW FILTER");
      const regExpes = phases
        .filter((phase) => phase.checked)
        .map((phase) => phase.regexp);
      const selectedLabels = [];
      selectedCluster.additional_info.loki_labels.forEach((label) => {
        for (let regExp of regExpes) {
          if (label.includes(regExp)) {
            selectedLabels.push(label);
            break;
          }
        }
      });
      console.log("SELECTED LABELS", selectedLabels);
      if (selectedLabels.length !== 0) {
        query += `,app=~"${selectedLabels.join("|")}"`;
      }
    }
    query += "}";
    if (selectedLogLevel == "Normal") {
      query += `!= "Debug" != "Trace" != "DEBUG" != "TRACE"`;
    }
    query += ' | line_format "{{(printf \\"%-20s\\" .app)}}   {{__line__}}"';
    const payload = {
      cluster_info: {
        label: selectedCluster.label,
        client_id: selectedCluster.client_id,
        environment: selectedCluster.environment,
        implementation_shortname: selectedCluster.implementation_shortname,
      },
      command: {
        name: "get-query-logs",
        searchId,
        query,
        userEmail: userEmail
      },
    };
    let reply = axios.post(
      `${process.env.REACT_APP_REGISTRY_URL}/command/issue_log_command`,
      payload
    );

    setWaitingForCOmmand(true);
    reply = await reply;
    console.log("reply.data.result", reply.data.result);
    setWaitingForCOmmand(false);
    setCommandResult(reply.data.result);
    if (reply.data.result.includes("started")) {
      toast(
        "Query started with execution, wait for result to appear in table bellow."
      );
    } else {
      toast(reply.data.result);
    }
  };

  const downloadLogByName = async (name) => {
    const payload = await axios.get(
      `${process.env.REACT_APP_REGISTRY_URL}/queried_logs/${name}`
    );
    const blob = new Blob([payload.data.logs]);
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${payload.data.name}.txt`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const shareLogByName = async (name) => {
    navigator.clipboard.writeText(name);
  };

  const renderTooltip = ({ text }) => <Tooltip>{text}</Tooltip>;

  const columns = [
    {
      dataField: "userEmail",
      text: "User",
      filter: textFilter(),
    },
    {
      dataField: "batchId",
      text: "BATCH ID",
      filter: textFilter(),
    },
    {
      dataField: "date",
      text: "DATE",
    },
    {
      dataField: "isNew",
      text: "IS NEW?",
      formatter: (isNew) => {
        return (
          <>
            {isNew ? (
              <span>
                <Badge bg="success">Yes</Badge>
              </span>
            ) : (
              <Badge bg="danger">No</Badge>
            )}
          </>
        );
      },
    },
    {
      dataField: "nameDownload",
      text: <div style={{ textAlign: "center" }}>DOWNLOAD</div>,
      formatter: (name) => {
        return (
          <div style={{ textAlign: "center", cursor: "pointer" }}>
            <OverlayTrigger
              placement="left"
              // delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip({
                text: `Click to Download`,
              })}
            >
              <Download
                className="ml-2"
                size={25}
                onClick={() => {
                  downloadLogByName(name);
                }}
              ></Download>
            </OverlayTrigger>
          </div>
        );
      },
    },
    // {
    // dataField: "nameShare",
    // text: "Share (copy to clipboard)",
    // formatter: (name) => {
    //     return <><Share className="ml-2" size={25} onClick={() => {shareLogByName(name)}}></Share></>;
    // }
    // },
  ];

  function handleBatchId(event) {
    setSearchId(event.target.value);
    setBatchIdValid(guidRegex.test(event.target.value));
  }

  return (
    <div className="mb-5 deploy-page">
      <ToastContainer hideProgressBar />
      <h2
        style={{ marginBottom: "1rem", display: "flex", alignItems: "center" }}
      >
        {selectedCluster === null && (
          <Spinner
            animation="grow"
            variant="primary"
            style={{ marginRight: "7px" }}
          />
        )}{" "}
        Log querying
      </h2>
      {selectedCluster !== null && (
        <div>
          <Row className="mt-5 justify-content-center flex-container">
            <Col className="align-self-end" sm={4}>
              <h4>Cluster</h4>
              <FloatingLabel label="Cluster">
                <Form.Select
                  value={selectedCluster.id}
                  onChange={(e) => {
                    setSelectedCluster(
                      clusters.find(
                        (cluster) => cluster.id === Number(e.target.value)
                      )
                    );
                  }}
                >
                  {clusters
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((cluster, key) => {
                      return (
                        <option key={key} value={cluster.id}>
                          {cluster.label}
                        </option>
                      );
                    })}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col className="align-self-end" sm={4}>
              <h4>Search by</h4>
              <FloatingLabel label="Id type">
                <Form.Select
                  value={selectedIdSearchOption.value}
                  onChange={(e) => {
                    setSelectedIdSearchOption(
                      idSearchOptions.find(
                        (option) => option.value == e.target.value
                      )
                    );
                  }}
                >
                  {idSearchOptions.map((option, key) => {
                    return (
                      <option key={key} value={option.value}>
                        {option.label}
                      </option>
                    );
                  })}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={4}>
              <h4>Log Level</h4>
              <FloatingLabel label="Log Level">
                <Form.Select
                  value={selectedLogLevel}
                  onChange={(e) => {
                    setLogLevel(e.target.value);
                  }}
                >
                  {["Normal", "Detailed"].map((logLevel, index) => {
                    return (
                      <option key={index} value={logLevel}>
                        {logLevel}
                      </option>
                    );
                  })}
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Row>
          {/* <Row className="mt-2 justify-content-center">
            <Col className="align-self-end" sm={6}>
              <h4>Search by</h4>
              <FloatingLabel label="Id type">
                <Form.Select
                  value={selectedIdSearchOption.value}
                  onChange={(e) => {
                    setSelectedIdSearchOption(
                      idSearchOptions.find(
                        (option) => option.value == e.target.value
                      )
                    );
                  }}
                >
                  {idSearchOptions.map((option, key) => {
                    return (
                      <option key={key} value={option.value}>
                        {option.label}
                      </option>
                    );
                  })}
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Row> */}
          <Row className="mt-2 justify-content-center">
            <Col className="align-self-end" sm={12}>
              <h4>{selectedIdSearchOption.label}</h4>
              <Form.Control
                placeholder={selectedIdSearchOption.label}
                value={searchId}
                onChange={(event) => handleBatchId(event)}
              />
            </Col>
          </Row>
          <Row className="mt-2 justify-content-center flex-container">
            <Col sm={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event) => {
                      onCheckBoxChange(event, setShowPhaseFilter);
                    }}
                    checked={showPhaseFilter}
                  />
                }
                label="Filter by phase"
              />
              <div
                style={{ display: showPhaseFilter ? "flex" : "none" }}
                className="filter-by-phase"
              >
                {phases.map((phase, index) => {
                  return (
                    <div key={index} style={{ lineHeight: "42px" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(event) => {
                              onFilterPhase(event, index);
                            }}
                            checked={phases[index].checked}
                          />
                        }
                        label={phases[index].label}
                      />
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
          <Row className="mt-5 justify-content-center">
            <Col className="justify-content-center d-flex" sm={6}>
              <Button
                className="deploy-btn"
                style={{ minWidth: "150px" }}
                onClick={onQuery}
                disabled={!batchIdValid}
              >
                <DoubleDownIcon /> Get Logs{" "}
              </Button>
            </Col>
          </Row>
          <Row className="mt-5 justify-content-center">
            <Col className="align-self-end" sm={6}>
              {waitingForCommand ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <BarLoader></BarLoader>
                  Waiting for command to execute
                </div>
              ) : (
                <div></div>
              )}
            </Col>
          </Row>
          <div className="d-flex align-items-center">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(event) => {
                    onCheckBoxChange(event, setShowOldQueries);
                  }}
                  checked={showOldQueries}
                />
              }
              label={"Show old queries"}
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(event) => {
                    onCheckBoxChange(event, setShowPersonalQueriesOnly);
                  }}
                  checked={showPersonalQueriesOnly}
                />
              }
              label={"Show only my queries"}
            />
            <p style={{ marginBottom: 0, marginLeft: "auto", color: "green" }}>
              Query result table refreshes every 10 seconds. Time since last
              refresh: {secondsSinceLastQuery}
            </p>
          </div>
          <BootstrapTable
            className="mt-2"
            keyField="name"
            bootstrap4
            data={filteredQueries}
            columns={columns}
            pagination={paginationFactory({ sizePerPage: 15 })}
            filter={filterFactory()}
            //striped
            bordered={false}
            hover
          />
        </div>
      )}
    </div>
  );
}

export default LogQueryPage;
