import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Image from "../image/image";

function SingleImagePage() {
  const [image, setImage] = useState([])
  let { id, tag } = useParams()
  useEffect(() => {
    async function fetchImages() {
      const payload = await axios.get(`${process.env.REACT_APP_URL}fetch/image/${id}`)
      setImage(payload.data)
    }
    fetchImages()
  }, [id])

  return (
    <Image autoExpand={true} focusTag={tag} name={image.name} id={id} />
  )
}

export default SingleImagePage;

