import { useEffect, useState } from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import PrivateRoute from "../private-route/private-route";
import LogoutIcon from "../svg-icons/logout-icon";
import "./navbar.scss";
import MenuClosedIcon from "../svg-icons/menu-closed-icon";
import MenuOpenedIcon from "../svg-icons/menu-opened-icon";
import ClusterIcon from "../svg-icons/cluster-icon";
import ImagesIcon from "../svg-icons/images-icon";
import QueriesIcon from "../svg-icons/queries-icon";
import CompareIcon from "../svg-icons/compare-icon";
import ValidationIcon from "../svg-icons/validation-icon";
import DeployIcon from "../svg-icons/deploy-icon";
import HistoryIcon from "../svg-icons/history-icon";
import QueueIcon from "../svg-icons/queue-icon";
import QueryIcon from "../svg-icons/query-icon";
import HomeIcon from "../svg-icons/home-icon";
import { useAuth } from "react-oidc-context";

function NavBar() {
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();
  const slug = location.pathname.split("/").slice(1).join("/");
  const [active, setActive] = useState("");
  const [menu, setMenu] = useState(false);
  const [isScreenBig, setIsScreenBig] = useState(window.innerWidth > 900);

  useEffect(() => {
    // Update the state when the window is resized
    const handleResize = () => {
      setIsScreenBig(window.innerWidth > 945);
    };

    // Add event listener to listen for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    isScreenBig ? setMenu(true) : setMenu(false);
  }, [isScreenBig]);

  useEffect(() => {
    setActive(slug)
  }, [slug])

  const navigate = (route) => {
    history.push(route);
  };

  function onMenuClick() {
    !isScreenBig && setMenu(false);
  }

  return (
    <Navbar
      activekey={active}
      onSelect={(selectedKey) => setActive(selectedKey)}
      variant="dark"
    >
      <Container fluid>
        <div className="mobile-menu">
          <Button onClick={() => setMenu(!menu)} variant="link">
            {!menu ? <MenuClosedIcon /> : <MenuOpenedIcon />}
          </Button>
        </div>
        <div className="brand-and-pages-wrapper">
          <Navbar.Brand onClick={onMenuClick}>
            <Nav.Link
              onClick={() => navigate("/")}
              eventKey=""
              className={active === "" ? "active-nav" : ""}
              title="Service Registry"
            >
              <span className="logo-sr">SR</span>
              <span>Service Registry</span>
            </Nav.Link>
          </Navbar.Brand>
          {menu && (
            <div className="nav-pages">
              <Nav className="ml-auto" onClick={onMenuClick}>
                <Nav.Link
                  eventKey=""
                  className={active === "" ? "active-nav" : ""}
                  onClick={() => navigate("/")}
                >
                  <HomeIcon /> Home
                </Nav.Link>
              </Nav>
              <Nav className="ml-auto" onClick={onMenuClick}>
                <Nav.Link
                  eventKey="clusters"
                  className={active === "clusters" || active.includes("cluster") ? "active-nav" : ""}
                  onClick={() => navigate("/clusters")}
                >
                  <ClusterIcon /> Clusters
                </Nav.Link>
              </Nav>
              <Nav className="ml-auto" onClick={onMenuClick}>
                <Nav.Link
                  eventKey="images"
                  className={active === "images" || active.includes("image") ? "active-nav" : ""}
                  onClick={() => navigate("/images")}
                >
                  <ImagesIcon /> Images
                </Nav.Link>
              </Nav>
              <Nav className="ml-auto" onClick={onMenuClick}>
                <Nav.Link
                  eventKey="queries"
                  className={active === "queries" ? "active-nav" : ""}
                  onClick={() => navigate("/queries")}
                >
                  <QueriesIcon /> Queries
                </Nav.Link>
              </Nav>
              <Nav className="ml-auto" onClick={onMenuClick}>
                <Nav.Link
                  eventKey="environment-comparisson"
                  className={
                    active === "environment-comparisson" ? "active-nav" : ""
                  }
                  onClick={() => navigate("/environment-comparisson")}
                >
                  <CompareIcon /> Env. comparisson
                </Nav.Link>
              </Nav>
              <Nav className="ml-auto" onClick={onMenuClick}>
                <Nav.Link
                  eventKey="validation"
                  className={
                    active === "validation"
                      ? "active-nav validation"
                      : "validation"
                  }
                  onClick={() => navigate("/validation")}
                >
                  <ValidationIcon /> Validation
                </Nav.Link>
              </Nav>
              <Nav className="ml-auto" onClick={onMenuClick}>
                <Nav.Link
                  eventKey="history"
                  className={active === "history-view" ? "active-nav" : ""}
                  onClick={() => navigate("/history-view")}
                >
                  <HistoryIcon /> History
                </Nav.Link>
              </Nav>
              <PrivateRoute roles={["DEVOPS"]}>
                <Nav className="ml-auto" onClick={onMenuClick}>
                  <Nav.Link
                    eventKey="deploy-page"
                    className={
                      active === "deploy-page"
                        ? "active-nav deploy-page"
                        : "deploy-page"
                    }
                    onClick={() => navigate("/deploy-page")}
                  >
                    <DeployIcon /> Deploy
                  </Nav.Link>
                </Nav>
              </PrivateRoute>
              <Nav className="ml-auto" onClick={onMenuClick}>
                <Nav.Link
                  eventKey="queue-report-page"
                  className={active === "queue-report-page" ? "active-nav" : ""}
                  onClick={() => navigate("/queue-report-page")}
                >
                  <QueueIcon /> Queue status
                </Nav.Link>
              </Nav>
              <Nav className="ml-auto" onClick={onMenuClick}>
                <Nav.Link
                  eventKey="log-query-page"
                  className={active === "log-query-page" ? "active-nav" : ""}
                  onClick={() => navigate("/log-query-page")}
                >
                  <QueryIcon /> Log query
                </Nav.Link>
              </Nav>
            </div>
          )}
        </div>
        <Nav style={{ marginRight: "0.5rem", marginTop: "auto  " }}>
          <Nav.Link eventKey="logout" onClick={() => {auth.removeUser(); auth.signoutRedirect()}}>
            <LogoutIcon /> Logout
          </Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
}

export default NavBar;
